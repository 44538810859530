import React from "react";
import "./CustomGallery.css";
import { v4 as uuidv4 } from 'uuid';

const CustomGallery = ({ photos, onClick }) => {
  return (
    photos && photos.length !== 0
      ? <>
        <div className="row">
        {
          photos.map((i) => {
            return (
                <div className="column" key={uuidv4()}>
                  <span className="helper"></span>
                  <img src={i.src} alt='' sizes="(min-width: 40em) 80vw, 100vw"></img>
                  <div className="topright" onClick={ () => {onClick(i.src)}}><span className="mbsc-ic mbsc-ic-close"></span></div>
                </div>
            )
          })
        }
        </div>
      </>
      : <></>
  );
}

export default CustomGallery;