import React from "react";

const buildPriceRating = (priceInfo) => {
    return Array.from({ length: priceInfo }, (x, i) => (<i key={i} className="fas fa-dollar-sign"></i>));
}


export default function buildFullPriceRating(priceInfo){
    return (
        <>
            <span className="dolar-checked">
                {
                    buildPriceRating(priceInfo)
                }
            </span>
            <span className="dolar-not-checked">
                {
                    buildPriceRating(4- priceInfo)
                }
            </span>
        </>
    );
}