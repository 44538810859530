export const locale = `/:locale([A-Za-z/-]{0,5})`;
export const routeRegex = `${locale}/:path*`;
export const vendorInitialState = {
    vendorName: '',
    isGoogleProfile: false,
    id: '',
    vendorEmail: '',
    activity: '',
    city: '',
    profileDescription: '',
    frenchProfileDescription: '',
    pricingInformation: 0,
    hasPictures: false,
    picturesUrls: [],
    pictures: [],
    picturesPreviews: [],
    calendars: [],
    publishProfile: false,
    publishCalendar: true,
    busyDays: [],
    freeDays: [],
    events: [],
    facebook: '',
    instagram: '',
    website: ''
}