import React, { useContext} from "react";
import "./NotFound.css";
import { FirebaseContext } from '../../firebaseConnect';

const NotFound = () => {
  const { t } = useContext(FirebaseContext)
  return (
    <div className="container-back">
      <div className="container-back-content">
        <h1>{t('are_you_lost', 'ARE YOU LOST???')}</h1>
        <div className="notfound">
        </div>
      </div>
    </div>
  );
}

export default NotFound;
