
  import React from 'react';
  import './spinner.css'

// import { css } from "@emotion/core";
// import RingLoader from "react-spinners/RingLoader";
  
  const Spinner = () => {
      return (
        <div className='spinner-wrapper'>
            <div className='spinner-circle'>
            </div>
        </div>
      )
  }
  

  //   ///https://www.davidhu.io/react-spinners/
  //   const override = css`
  //   display: flex;
  //   align-items: center;    
  //   margin: 0 auto;
  //   border-color: #ec9177;
  // `;
  export default Spinner