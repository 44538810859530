import React, { useContext, useEffect, useState, useCallback } from 'react';
import { FirebaseContext } from '../../firebaseConnect';
import Vendor from "../Vendor/Vendor";
import './SearchBar.css';
import mobiscroll from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { v4 as uuidv4 } from 'uuid';
import buildEvents from '../Agenda/buildEvents.js'
import * as moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import { normalizeSync } from 'normalize-diacritics';
const DATA = require('../../data/orderedCities');
const VENDORTYPES = require('../../data/vendorTypes');


const VendorDirectory = () => {
  const [cities, setCities] = useState(DATA.ORDERED_CITIES);
  const { firebase, user, searchContext, setSearchContext, t, language, addIsLoading, removeIsLoading, resetIsLoading } = useContext(FirebaseContext)
  const [vendorsState, setVendorsState] = useState([])
  const [isAdmin, setIsAdmin] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);

  const getVendors = useCallback(async (context, limit, last, clearVendors) => {
    const handleSnapshot = (snapshot, date, clearVendors) => {
      const vendors = snapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data(),
          builtEvents: date ? buildEvents(t, doc.data().events ?? [], moment(), date, date) : []
        }
      })
      let finalVendors = vendors;

      console.log('99.--->date', date);
      console.log('100.--->vendors', vendors);
      if (date) {
        const dateToExclude = date.format('YYYYMMDD')
        finalVendors = finalVendors.filter(v => !v.events.includes(dateToExclude));
        finalVendors = finalVendors.filter(v => !(v.builtEvents.length > 0 && v.builtEvents.filter(e => e.id === dateToExclude).length > 0));
      }
      const index = snapshot.docs.length - 1;
      setLastVisible(snapshot.docs[index]?.data()?.id);
      if (clearVendors) {
        setVendorsState(finalVendors);
      } else {
        setVendorsState(previous => {
          return [...previous, ...finalVendors]
        });
      }
    }

    console.log('1000099.--->context', context);
    // addIsLoading('VendorDirectory - handleSnapshot');
    const date = context.date === null ?  null : moment(context.date, "MM-DD-YYYY");
    let vendorBasicSearch = firebase.db
      .collection('vendors')
      .where("hasPictures", "==", true)
      .where("isAdmin", "==", false)
      .where("publishProfile", "==", true)

    console.log('4. %%%%%%%% last', last);
    await firebase.db.collection('searchs').doc(uuidv4()).set({ ...context, searchDate: new Date() })
    if (context.date == null && context.vendor == null && context.city == null) {
      if (last) {
        vendorBasicSearch.orderBy("id").startAfter(last).limit(limit).get().then(snapshot => handleSnapshot(snapshot, null, clearVendors));
      } else {
        vendorBasicSearch.orderBy("id").limit(limit).get().then(snapshot => handleSnapshot(snapshot, null, clearVendors));
      }
    }
    else {
      if (context.city !== null) {
        vendorBasicSearch = vendorBasicSearch.where("city", '==', context.city);
      }
      if (context.vendor !== null) {
        vendorBasicSearch = vendorBasicSearch.where("activity", '==', context.vendor);
      }
      if (last) {
        vendorBasicSearch.orderBy("id").startAfter(last).limit(limit).get().then(snapshot => handleSnapshot(snapshot, date, clearVendors));
      } else {
        vendorBasicSearch.orderBy("id").limit(limit).get().then(snapshot => handleSnapshot(snapshot, date, clearVendors));
      }
    }

    // resetIsLoading('VendorDirectory - handleSnapshot');
  }, [])

  useEffect(() => {
    getVendors(searchContext, 3, null, false);
  }, [])

  useEffect(() => {
    setIsAdmin(user && user.isAdmin)
  }, [user])

  const fetchMoreData = () => {
    console.log('loading more....');
    getVendors(searchContext, 8, lastVisible, false);
  }

  const handleSearch = () => {
    getVendors(searchContext, 3, null, true);
  }

  const handleCleanSearch = () => {
    setSearchContext({
      vendor: null,
      date: null,
      city: null
    })
    getVendors({
      vendor: null,
      date: null,
      city: null
    }, 3, null, true);
  }

  const handleDateChange = (date) => {
    setSearchContext(prevState => {
      return {
        ...prevState,
        date: date.valueText,
      }
    })
  }

  const handleCityChange = (city) => {
    setSearchContext(prevState => {
      return {
        ...prevState,
        city: city.valueText,
      }
    })
  }


  const calculateVendorValues = (vendorValue) => {
    var vendorLength = language.startsWith('en') ? VENDORTYPES.vendorLengthsEnglish[vendorValue] : VENDORTYPES.vendorLengthsFrench[vendorValue]
    return {
      vendor: vendorValue,
      vendorLength: vendorLength,
    }
  }

  const handleVendorChange = (instance) => {
    setSearchContext(prevState => {
      const values = calculateVendorValues(instance.getVal());
      return {
        ...prevState,
        vendor: values.vendor,
      }
    })
  }

  const onFilter = (event, inst) => {
    const normalizeed = normalizeSync(event.filterText).toLowerCase();
    console.log('1. #########################', normalizeed);
   
    const filteredItems = DATA.ORDERED_CITIES.filter(city => city.clean_value.includes(normalizeed));
    console.log('2. #########################', filteredItems);
    setCities(filteredItems);
        
    return false;
  }

  return (
    <div className="container-back">
      <div className="container-back-content">
        <div className="searchbar-fields">
          <div className="searchbar-iam">{t('im_getting_married', 'I\'m getting married in')}</div>
          <div className="searchbar-city">
            <mobiscroll.Select
              value={searchContext.city == null ? '' : searchContext.city }
              touchUi={false}
              onFilter={onFilter}
              filter={true}
              data={cities}
              buttons= {['cancel', 'clear']}
              placeholder={t('_City', 'City')}
              inputClass="form-control"
              onSet={handleCityChange}
            >
            </mobiscroll.Select>
          </div>
          <div className="searchbar-on">{t('on the', 'on the')}</div>
          <div className="searchbar-date">
            <mobiscroll.Date
              placeholder={t('_Date', 'Date')}
              buttons= {['cancel', 'clear', 'set']}
              value={searchContext.date == null ? '' : searchContext.city }
              onSet={handleDateChange}
              className="form-control"
            />
          </div>
          <div className="searchbar-and-iam">{t('and I am loking for a', 'and I am loking for a')}</div>
          <div className="searchbar-vendor">
            <mobiscroll.Select
              value={searchContext.vendor == null ? '' : searchContext.vendor }
              touchUi={false}
              filter={true}
              buttons= {['cancel', 'clear']}
              onSet={(value, instance) => handleVendorChange(instance)}
              data={language.startsWith('en') ? VENDORTYPES.EN_TYPES : VENDORTYPES.FR_TYPES}
              placeholder={t('_Vendor', 'Vendor')}
              inputClass="form-control"
            >
            </mobiscroll.Select>
          </div>
          <div className="searchbar-buttons">
            <mobiscroll.Button onClick={handleSearch}>Search</mobiscroll.Button>
            <mobiscroll.Button onClick={handleCleanSearch} icon="close"></mobiscroll.Button>
          </div>
        </div>
        <div id="scrollableDiv" style={{ height: 850, overflow: "auto" }}>
          <InfiniteScroll
            dataLength={vendorsState.length}
            next={fetchMoreData}
            hasMore={true}
            scrollableTarget="scrollableDiv"
            loader={!lastVisible ?? <h4>Loading...</h4>}
          >
            {
              vendorsState.length === 0 ? (
                <p>{t('No Vendor Profiles', 'No Vendor Profiles')}</p>
              ) : (
                  vendorsState.map((vendor) => {
                    return <Vendor key={vendor.id} {...vendor} isAdmin={isAdmin} />;
                  })
                )
            }
          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
}


export default VendorDirectory
