import { useEffect, useState, useCallback } from 'react';
import firebase from '../firebaseConnect';
import { vendorInitialState } from '../constants.js'

const Config = require("../firebaseConnect/apiGoogleconfig.json");

const useAuth = (redirectAfterLogin, redirectAfterSignout, addIsLoading, removeIsLoading, resetIsLoading) => {
    const [authUser, setAuthUser] = useState(null);
    const [vendor, setVendor] = useState(null);

    const handleSignOut = event => {
        event.preventDefault();
        firebase.auth.signOut().then(() => {
                setVendor(null);
                setAuthUser(null);
            resetIsLoading();
            redirectAfterSignout()
        }).catch((error) => {
            console.error(error);
        });
    }

  
     useEffect(() => {
        firebase.auth.onAuthStateChanged(user => {
            setAuthUser(user);
        })
    }, [])

    useEffect(() => {
        const handleAuth = async () => {
            let userProfile = null;
            if (authUser !== null) {
                const vendorRef = firebase.db.collection('vendors').doc(authUser.uid)
                vendorRef.onSnapshot(async doc => {
                    if (doc.exists) {
                            userProfile = {
                                ...doc.data(),
                                id: doc.id,
                                pictures: [],
                                picturesPreviews: []
                            };
                    } else {
                            userProfile = {
                                ...vendorInitialState,
                                vendorName: authUser?.displayName,
                                isGoogleProfile: authUser?.displayName !== null,
                                id: authUser.uid,
                                vendorEmail: authUser?.email,
                                pictures: [],
                                picturesPreviews: [],
                                picturesUrls: [],
                                publishProfile: false,
                                publishCalendar: true,
                                isAdmin: false,
                                busyDays: [],
                                freeDays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                                events: [],
                                calendar: [],
                                emailConfirmed: false,
                                pricingInformation: 1
                            }                       
                    }
                    setVendor(userProfile);
                    removeIsLoading('useAuth - handleAuth');
                    redirectAfterLogin(authUser, userProfile);
                });
            }
            if (authUser !== null && userProfile !== null) {
                await firebase.db.collection('vendors').doc(authUser.uid).set(userProfile)
            }
        }
        handleAuth();
    }, [authUser])
    
    return { vendor, handleSignOut };
}

export default useAuth;