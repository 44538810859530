import React, { useContext, useState, useRef } from "react";
import "./VendorContact.css";
import mobiscroll from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { FirebaseContext } from '../../firebaseConnect';
import { v4 as uuidv4 } from 'uuid';
import ReCAPTCHA from "react-google-recaptcha";
const DATA = require('../../data/orderedCities');

const intialState = {
  name: null,
  email: null,
  phone: '',
  eventDate: '',
  city: '',
  message: null,
  numberOfGuests: 0,
  captcha: null
}

const VendorContact = ({ vendor }) => {
  const { firebase, t, addIsLoading, removeIsLoading } = useContext(FirebaseContext)
  const [formValue, setFormValue] = useState(intialState)

  const handleInputChange = event => {
    event.persist()
    setFormValue(previous => {
      return {
        ...previous,
        [event.target.name]: event.target.value
      }
    })
  }

  const handleSet = (name, value) => {
    setFormValue(previous => {
      return {
        ...previous,
        [name]: value
      }
    })
  }

  const handleSubmit = e => {
    e.preventDefault();
    sendEmail()
  }

  const sendEmail = async () => {
    addIsLoading('VendorContact - sendEmail');
    const callable = firebase.functions.httpsCallable('sendEmailSES');
    const msg = {
      to: vendor.vendorEmail,
      bcc: formValue.email,
      from: 'contact@jolirendezvous.com',
      subject: `${t('You received a message from ', 'Contact JoliRendezvous.ca - You received a message from ')}${formValue.name}`,
      text: `${t('email_Hello', 'Hello, ')} ${vendor.vendorName}\n${t('email_You have a new message from', 'You have a new message from:')}\n ${formValue.name}\n${t('Email', 'Email')}:${formValue.email}\n${t('Phone Number', 'Phone Number')}:${formValue.phone}\n ------ \n${t('Date of event', 'Date of event')}:${formValue.eventDate}\n${t('_City', 'City')}:${formValue.city}\n${t('Number of guests', 'Number of guests')}:${formValue.numberOfGuests}\n${t('Message', 'Message')}:${formValue.message}`,
      html: `<p>${t('email_Hello', 'Hello')}<strong>${vendor.vendorName}</strong></p><p>${t('email_You have a new message from', 'You have a new message from:')} <strong>${formValue.name}</strong>    <ul>        <li>${t('Email', 'Email')}: <strong></strong>${formValue.email}</li>        <li>${t('Phone Number', 'Phone Number')}: <strong>${formValue.phone}</strong></li>        <li>${t('Date of event', 'Date of event')}: <strong>${formValue.eventDate}</strong></li>        <li>${t('_City', 'City')}: <strong>${formValue.city}</strong></li>        <li>${t('Number of guests', 'Number of guests')}: <strong>${formValue.numberOfGuests}</strong></li>        <li>${t('Message', 'Message')}: <strong>${formValue.message}</strong></li>      </ul></p>`,
    };

    await firebase.db.collection('emailexchanges').doc(uuidv4()).set({ ...formValue })

    return callable(msg).then(res => {
      removeIsLoading('VendorContact - sendEmail');
      setFormValue(intialState);
      eventRef.current.instance.show();
    }
    ).catch(err => console.error('#### err', err));
  }

  const eventRef = useRef(null);
  const btn = [{
    text: 'Ok',
    handler: 'set'
  }
  ];

  const onChange = (value) => {
    console.log("Captcha value:", value);
    setFormValue(previous => {
      return {
        ...previous,
        captcha: value
      }
    })
  }

  return (
    <mobiscroll.Form className="mbsc-form-grid vendor-contact-main" theme="savemydate" onSubmit={handleSubmit}>
      <div className="mbsc-grid">
        <mobiscroll.Popup
          ref={eventRef}
          display="center"
          buttons={btn}
        >
          <div className="mbsc-align-center mbsc-padding">
            <h4 className="md-text-center">{t('email_sent', 'Your email has been sent!')}</h4>
          </div>
        </mobiscroll.Popup>
        <div className="mbsc-row mbsc-justify-content-center">
          <div className="mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-xl-12">
            <h4 className='vendor-contact-title'>{t('Contact Vendor', 'Contact Vendor')}</h4>
          </div>
          <div className="mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-xl-12">
            <mobiscroll.Input placeholder={t("Enter your name", "Enter your name")} name="name" className='vendor-fix-row' onChange={handleInputChange} value={formValue.name}>
              {t('name', 'Name')}
            </mobiscroll.Input>
          </div>
          <div className="mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-xl-12">
            <mobiscroll.Input placeholder={t("Enter your email", "Enter your email")} type="email" name="email" onChange={handleInputChange} value={formValue.email}>
              {t('Email', 'Email')}
            </mobiscroll.Input>
          </div>
          <div className="mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-xl-12">
            <mobiscroll.Input placeholder={t("Enter your phone number", "Enter your phone number")} type="phone" name="phone" onChange={handleInputChange} value={formValue.phone}>
              {t('Phone', 'Phone')}
            </mobiscroll.Input>
          </div>
          <div className="mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-xl-12">
            <mobiscroll.Date
              placeholder={t('please_select_a_date_for', 'Please Select a date for your event...')}
              name='eventDate'
              onSet={value => handleSet('eventDate', value.valueText)} value={formValue.eventDate}>
              <mobiscroll.Input>{t('_Date', 'Date')}</mobiscroll.Input>
            </mobiscroll.Date>
          </div>
          <div className="mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-xl-12">
            <mobiscroll.Select
              touchUi={false}
              filter={true}
              data={DATA.ORDERED_CITIES}
              name='city'
              placeholder={t('Please select where your event is taking place', 'Please select where your event is taking place')}
              showOnTap={true}
              showOnFocus={true}
              layout='liquid'
              onSet={value => handleSet('city', value.valueText)}
              value={formValue.city}>
              <mobiscroll.Input>{t('_City', 'City')}</mobiscroll.Input>
            </mobiscroll.Select>
          </div>
          <div className="mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-xl-12">
            <mobiscroll.Input type="number" placeholder={t('How many Guests?', 'How many Guests?')} name='numberOfGuests' onChange={handleInputChange} value={formValue.numberOfGuests}>
              {t('Number of guests', 'Number of guests')}
            </mobiscroll.Input>
          </div>
          <div className="mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-xl-12">
            <mobiscroll.Textarea name='message' onChange={handleInputChange} value={formValue.message}>
              {t('Your Message', 'Your Message')}
            </mobiscroll.Textarea>
          </div>
        </div>
        <div className="mbsc-row mbsc-justify-content-center">
          <div className="mbsc-col-sm-10 mbsc-col-md-8 mbsc-col-xl-8">
            <div className="mbsc-btn-group-block">
            <div className="vendor-contact-captcha">
              {/* https://www.google.com/recaptcha/admin/site/433185466/setup */}
              <ReCAPTCHA
                sitekey="6Lce49EZAAAAAJ_5xMziZPmCvkwNq9QuGXsSigsK"
                onChange={onChange}
              />
            </div>
            </div>
          </div>
        </div>
        <div className="mbsc-row mbsc-justify-content-center">
          <div className="mbsc-col-sm-10 mbsc-col-md-8 mbsc-col-xl-8">
            <div className="mbsc-btn-group-block">
              <mobiscroll.Button type='submit' disabled={formValue.message === null || formValue.captcha === null || formValue.email === null || formValue.name === null } >{t('Send Email', 'Send Email')}</mobiscroll.Button>
            </div>
          </div>
        </div>
      </div>
    </mobiscroll.Form>

  );
}

export default VendorContact;
