import React, { useRef, useContext, useState, useEffect } from 'react';
import mobiscroll from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { FirebaseContext } from '../../firebaseConnect';
import "./Agenda.css"
import buildEvents from './buildEvents.js'

const moment = require('moment')
const daysOfWeek = {
    Sunday: "SU",
    Monday: "MO",
    Tuesday: "TU",
    Wednesday: "WE",
    Thursday: "TH",
    Friday: "FR",
    Saturday: "SA"
}

const GenericAgenda = ({ vendor }) => {
    const { firebase, t, addIsLoading, removeIsLoading } = useContext(FirebaseContext);
    const initialState = {
        selectedDate: moment(),
        selectedDateToPopUp: '',
        eventDate: [],
        preventSet: false,
        busyDays: vendor.busyDays,
        freeDays: vendor.freeDays,
        isSpecificDayFree: '',
        events: vendor.events ?? [],
        eventsToShow: [],
        newEvents: [],
        toRemoveEvents: []
    }
    const [state, setState] = useState(initialState);
    const eventCalendarRef = useRef(null);
    const eventRef = useRef(null);

    useEffect(() => {
        const events = buildEvents(t, vendor.events ?? [], moment())
        setState(prevState => {
            return {
                ...prevState,
                eventsToShow: events
            }
        })

    }, [])



    const handleSubmit = async e => {
        e.preventDefault();
        addIsLoading('GenericAgenda - handleSubmit');

        if (vendor) {
            const vendorRef = firebase.db.collection('vendors').doc(vendor.id)
            const doc = await vendorRef.get()
            if (doc.exists) {
                vendor.pictures = [];
                console.log('1. ----->vendor', vendor);
                console.log('2. ----->vendor', state.busyDays);
                console.log('3. ----->vendor', state.events);
                await vendorRef.update({
                    ...vendor,
                    busyDays: state.busyDays,
                    events: state.events.filter(e => !!e)
                })

                setState(prevState => {
                    return {
                        ...prevState,
                        toRemoveEvents: [],
                        newEvents: []
                    }
                })

                removeIsLoading('GenericAgenda - handleSubmit')
            }
        }
    }

    const dayOfWeekChange = (event) => {
        event.persist()
        const day = event.target.value;

        setState(prevState => {
            let newbusyDays = prevState.busyDays.slice(0);
            let newfreeDays = prevState.freeDays.slice(0);
            let newEvents = prevState.events.slice(0);
            let newEventsToShow = prevState.eventsToShow.slice(0);

            if (event.target.checked) {
                newbusyDays.push(day);
                newfreeDays = prevState.freeDays.filter(d => d !== day)
                const recurringToSave = `RRULE:FREQ=WEEKLY;WKST=MO;BYDAY=${daysOfWeek[day]}`;
                newEvents.push({
                    id: daysOfWeek[day],
                    recurring: recurringToSave
                })

                const newSelectedDate = moment(event.firstDay).format('YYYYMMDD')
                newEventsToShow = buildEvents(t, newEvents, newSelectedDate);
            }
            else {
                newfreeDays.push(day);
                newbusyDays = prevState.busyDays.filter(d => d !== day)
                newEvents = prevState.events.filter(d => d.id !== daysOfWeek[day])
                newEventsToShow = prevState.eventsToShow.filter(d => !d.recurring.includes(`BYDAY=${daysOfWeek[day]}`))
            }
            return {
                ...prevState,
                busyDays: newbusyDays,
                freeDays: newfreeDays,
                events: newEvents,
                newEvents: newEvents,
                eventsToShow: newEventsToShow
            }
        });
    }

    const handleAddEvent = (event) => {
        event.persist()
        setState(prevState => {
            const events = prevState.events.slice(0)
            const eventsToShow = prevState.eventsToShow.slice(0)
            if (events.every(e => e.id !== state.selectedDate)) {
                events.push({
                    id: state.selectedDate.format("YYYYMMDD"),
                    start: state.selectedDate.format("YYYYMMDD"),
                    end: state.selectedDate.format("YYYYMMDD"),
                    text: t('agenda_not_available', 'Not Available'),
                    color: '#f4511e'
                })
                eventsToShow.push({
                    id: state.selectedDate.format("YYYYMMDD"),
                    start: moment(state.selectedDate, "YYYYMMDD"),
                    end: moment(state.selectedDate, "YYYYMMDD"),
                    text: t('agenda_not_available', 'Not Available'),
                    color: '#f4511e'
                })
            }
            const newEvents = prevState.newEvents.slice(0)
            if (newEvents.every(e => e.id !== state.selectedDate) && vendor.events.every(e => e !== state.selectedDate)) {
                newEvents.push(state.selectedDate)
            }
            const toRemoveEvents = prevState.toRemoveEvents.filter(i => i !== state.selectedDate)

            return {
                ...prevState,
                events: events,
                newEvents: newEvents,
                eventsToShow: eventsToShow,
                toRemoveEvents: toRemoveEvents
            }
        });
    }

    const onDayChange = (event, inst) => {
        // const newSelectedDate = moment(event.date).format('YYYYMMDD')
        // if (newSelectedDate !== state.selectedDate) {
        setState(prevState => {
            return {
                ...prevState,
                selectedDate: moment(event.date)
            }
        });
        // }
    }



    const onPageChange = (event, inst) => {
        const newSelectedDate = moment(event.firstDay).format('YYYYMMDD')
        const eventsToShow = buildEvents(t, state.events, newSelectedDate);
        const selectedDateMonth = moment(state.selectedDate).month();
        const pageMonth = moment(event.firstDay).month();
        if (selectedDateMonth !== pageMonth) {
            setState(prevState => {
                return {
                    ...prevState,
                    selectedDate: event.firstDay,
                    eventsToShow: eventsToShow
                }
            });
        }
    }

    const handleEventSelect = (event, inst) => {
        const eventToShow = event.event.id ?? moment(event.date).format('YYYYMMDD');
        setState(prevState => {
            return {
                ...prevState,
                selectedDateToPopUp: eventToShow
            }
        });
        eventRef.current.instance.show();
    }


    const onChangeDate = (event, instance) => {
        const newDate = instance.getDate();
        setState(prevState => {
            return {
                ...prevState,
                selectedDate: newDate
            }
        });
        eventCalendarRef.current.instance.navigate(newDate)
    }
    const btn = [
        {
            text: t('yes', 'yes'),
            icon: 'checkmark',
            handler: function (event, inst) {

                setState(prevState => {
                    const shownEventToRemove = prevState.eventsToShow.filter(i => i.id === state.selectedDateToPopUp)[0];
                    if (shownEventToRemove) {
                        console.log('1....  prevState.events', prevState.events);
                        console.log('2....  shownEventToRemove.recurring', shownEventToRemove.recurring);
                        const evenToModify = prevState.events.filter(i => i.recurring.startsWith(shownEventToRemove.recurring))[0];
                        const recurring = `${evenToModify.recurring}\nEXDATE:${state.selectedDateToPopUp}`;
                        const newEvent = {
                            ...evenToModify,
                            recurring
                        };
                        let newEvents = prevState.events.filter(i => !i.recurring.startsWith(shownEventToRemove.recurring));
                        newEvents = [...newEvents, newEvent]
                        const newEventsToShow = prevState.eventsToShow.filter(e => e.id !== state.selectedDateToPopUp);
                        return {
                            ...prevState,
                            events: newEvents,
                            eventsToShow: newEventsToShow
                        }
                    }
                    return prevState;
                });
                inst.hide();
            }
        },
        {
            text: t('no', 'no'),
            icon: 'close',
            handler: function (event, inst) {
                inst.hide();
            }
        }
    ];

    return (
        <>
            <mobiscroll.Form theme="savemydate" onSubmit={handleSubmit}>
                <div className="mbsc-grid">
                    <>
                        <div className="mbsc-row">
                            <div className="mbsc-col-sm-12 mbsc-col-md-4">
                                <mobiscroll.FormGroup>
                                    <div className="agenda-divider">
                                        <mobiscroll.FormGroupTitle>{t('agenda_set_avaliability', 'Set Availability')}</mobiscroll.FormGroupTitle>
                                    </div>
                                    <mobiscroll.FormGroupContent>
                                        <mobiscroll.FormGroup>
                                            <mobiscroll.FormGroupTitle>{t('agenda_set_following_days', 'SET FOLLOWING DAYS AS UNAVAILABLE')}</mobiscroll.FormGroupTitle>
                                            <mobiscroll.Segmented name="dayOfWeek" value="Sunday" multiSelect={true} checked={state.busyDays.includes('Sunday')} onChange={dayOfWeekChange}>
                                                {t('agenda_SUNDAY', 'S')}
                                            </mobiscroll.Segmented>
                                            <mobiscroll.Segmented name="dayOfWeek" value="Monday" multiSelect={true} checked={state.busyDays.includes('Monday')} onChange={dayOfWeekChange}>
                                                {t('agenda_MONDAY', 'M')}
                                            </mobiscroll.Segmented>
                                            <mobiscroll.Segmented name="dayOfWeek" value="Tuesday" multiSelect={true} checked={state.busyDays.includes('Tuesday')} onChange={dayOfWeekChange}>
                                                {t('agenda_TUESDAY', 'T')}
                                            </mobiscroll.Segmented>
                                            <mobiscroll.Segmented name="dayOfWeek" value="Wednesday" multiSelect={true} checked={state.busyDays.includes('Wednesday')} onChange={dayOfWeekChange}>
                                                {t('agenda_WEDNESDAY', 'W')}
                                            </mobiscroll.Segmented>
                                            <mobiscroll.Segmented name="dayOfWeek" value="Thursday" multiSelect={true} checked={state.busyDays.includes('Thursday')} onChange={dayOfWeekChange}>
                                                {t('agenda_THURSDAY', 'T')}
                                            </mobiscroll.Segmented>
                                            <mobiscroll.Segmented name="dayOfWeek" value="Friday" multiSelect={true} checked={state.busyDays.includes('Friday')} onChange={dayOfWeekChange}>
                                                {t('agenda_FRIDAY', 'F')}
                                            </mobiscroll.Segmented>
                                            <mobiscroll.Segmented name="dayOfWeek" value="Saturday" multiSelect={true} checked={state.busyDays.includes('Saturday')} onChange={dayOfWeekChange}>
                                                S
                                                        </mobiscroll.Segmented>
                                        </mobiscroll.FormGroup>
                                        <div className='generic-agenda-form-or'></div>
                                        <mobiscroll.FormGroup className='generic-agenda-fix-form-group'>
                                            <mobiscroll.FormGroupTitle>{t('agenda_set_specfific_date', 'SET SPECIFIC DATE AS UNAVAILABLE')}</mobiscroll.FormGroupTitle>
                                        </mobiscroll.FormGroup>
                                        <mobiscroll.FormGroup>
                                            <mobiscroll.Date placeholder={t('DATE', 'DATE')}
                                                onSet={onChangeDate}
                                                value={state.selectedDate}
                                                showOnFocus={true} />
                                        </mobiscroll.FormGroup>
                                        <mobiscroll.FormGroup className='generic-agenda-fix-form-group'>
                                            <div className="mbsc-row mbsc-justify-content-center">
                                                <div className="mbsc-col-sm-6 mbsc-col-md-6 mbsc-col-xl-6">
                                                    <div className="mbsc-btn-group-block">
                                                        <mobiscroll.Button className='generic-agenda-change-color' onClick={handleAddEvent}>{t('agenda_add_event', 'Add Event')}</mobiscroll.Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </mobiscroll.FormGroup>
                                        <div className='generic-agenda-form-or'></div>
                                        <mobiscroll.FormGroup>
                                            <div className="mbsc-row mbsc-justify-content-center">
                                                <div className="mbsc-col-sm-9 mbsc-col-md-9 mbsc-col-xl-9">
                                                    <div className="mbsc-btn-group-block">
                                                        <mobiscroll.Button type='submit'>{t('agenda_save', 'Save Agenda')}</mobiscroll.Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </mobiscroll.FormGroup>
                                    </mobiscroll.FormGroupContent>
                                </mobiscroll.FormGroup>
                            </div>
                            <div className="mbsc-col-sm-12 mbsc-col-md-8">
                                <mobiscroll.FormGroup>
                                    <div className="agenda-divider">
                                        <mobiscroll.FormGroupTitle>{t('agenda_events_in_selected_calendars', 'Events in selected calendars')}</mobiscroll.FormGroupTitle>
                                    </div>
                                    <mobiscroll.Popup
                                        ref={eventRef}
                                        display="center"
                                        buttons={btn}
                                    >
                                        <div className="mbsc-align-center mbsc-padding">
                                            <h4 className="md-text-center">{t('agenda_do_you_want', 'Do you want to make ')}{moment(state.selectedDateToPopUp, 'YYYYMMDD').format('L')}{t('agenda_available?', 'Available?')}</h4>
                                        </div>
                                    </mobiscroll.Popup>
                                    <mobiscroll.Eventcalendar
                                        theme="savemydate"
                                        display="inline"
                                        onEventSelect={handleEventSelect}
                                        ref={eventCalendarRef}
                                        data={state.eventsToShow}
                                        view={{
                                            calendar: {
                                                labels: true
                                            }
                                        }}
                                        onPageChange={onPageChange}
                                        onDayChange={onDayChange}
                                    />
                                </mobiscroll.FormGroup>
                            </div>
                        </div>
                    </>
                </div>
            </mobiscroll.Form>
        </>
    )

}

export default GenericAgenda;

