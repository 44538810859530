exports.EN_TYPES = [
    {value: 'Wedding venues', text: 'Wedding venues'},
    {value: 'Wedding Planner', text: 'Wedding planner'},
    {value: 'Florist', text: 'Florist'},
    {value: 'Photographer', text: 'Photographer'},
    {value: 'Videographer', text: 'Videographer'},
    {value: 'Hair and Make Up Artist', text: 'Hair and Make Up Artist'},
    {value: 'Catering, drinks and food', text: 'Catering, drinks and food'},
    {value: 'Wedding Cake', text: 'Wedding Cake'},
    {value: 'Wedding favours', text: 'Wedding favours'},
    {value: 'Wedding music', text: 'Wedding music'},
    {value: 'DJ', text: 'DJ'},
    {value: 'Wedding transport', text: 'Wedding transport'},
    {value: 'Wedding decorations', text: 'Wedding decorations'},
    {value: 'HoneyMoon', text: 'HoneyMoon'},
    {value: 'Officiant', text: 'Officiant'},
    {value: 'Photobooth', text: 'Photobooth'},
    {value: 'Event rentals', text: 'Event rentals'},
    {value: 'Stationery', text: 'Stationery'},
    {value: 'Jewellery', text: 'Jewellery'},
    {value: 'Bride\'s outfit', text: 'Bride\'s outfit'},
    {value: 'Groom\'s outfit', text: 'Groom\'s outfit'},
    {value: 'OTHER', text: 'OTHER'},
]

exports.FR_TYPES = [
    {value: 'Wedding venues'            , text: 'Lieu de mariage'},
    {value: 'Wedding Planner'           , text: 'Organisatrice de mariage'},
    {value: 'Florist'                   , text: 'Fleuriste'},
    {value: 'Photographer'              , text: 'Photographe'},
    {value: 'Videographer'              , text: 'Vidéaste'},
    {value: 'Hair and Make Up Artist'   , text: 'Mise en beauté'},
    {value: 'Catering, drinks and food' , text: 'Restauration, mets et boissons'},
    {value: 'Wedding Cake'              , text: 'Gâteaux de mariage'},
    {value: 'Wedding favours'           , text: 'Cadeaux de mariage'},
    {value: 'Wedding music'             , text: 'Musique'},
    {value: 'DJ'                        , text: 'DJ'},
    {value: 'Wedding transport'         , text: 'Transport'},
    {value: 'Wedding decorations'       , text: 'Décor de mariage'},
    {value: 'HoneyMoon'                 , text: 'Voyage de Noce'},
    {value: 'Officiant'                 , text: 'Célébrant'},
    {value: 'Photobooth'                , text: 'PhotoBooth'},
    {value: 'Event rentals'             , text: 'Location de mobilier'},
    {value: 'Stationery'                , text: 'Papeterie'},
    {value: 'Jewellery'                 , text: 'Bijoux'},
    {value: 'Bride\'s outfit'           , text: 'Tenue de la mariée'},
    {value: 'Groom\'s outfit'           , text: 'Tenue du marié '},
    {value: 'OTHER'                     , text: 'AUTRES'},
]


exports.vendorLengthsFrench = {
    'Wedding venues'          : 17.7,
    'Wedding planner'         : 28,
    'Florist'                 : 10.25,
    'Photographer'            : 15,
    'Videographer'            : 10.5,
    'Hair and Make Up Artist' : 17.5,
    'Catering, drinks and food': 33.8,
    'Wedding Cake'            : 22.25,
    'Wedding favours'         : 22.7,
    'Wedding music'           : 10.3,
    'DJ'                      : 3.7,
    'Wedding transport'       : 11.3,
    'Wedding decorations'     : 19.8,
    'HoneyMoon'               : 18,
    'Officiant'               : 11.7,
    'Photobooth'              : 14,
    'Event rentals'           : 23,
    'Stationery'              : 11.25,
    'Jewellery'               : 7.5,
    'Bride\'s outfit'         : 21,
    'Groom\'s outfit'         : 17.5,
    'OTHER'                   : 10,
}
  
exports.vendorLengthsEnglish = {
    'Wedding venues'          : 18.8,
    'Wedding planner'         : 19.5,
    'Florist'                 : 7.75,
    'Photographer'            : 15.7,
    'Videographer'            : 15.7,
    'Hair and Make Up Artist' : 27,
    'Catering, drinks and food': 28,
    'Wedding Cake'            : 16.75,
    'Wedding favours'         : 19,
    'Wedding music'           : 17.5,
    'DJ'                      : 3.7,
    'Wedding transport'       : 21.5,
    'Wedding decorations'     : 24,
    'HoneyMoon'               : 14.3,
    'Officiant'               : 14.3,
    'Photobooth'              : 13.75,
    'Event rentals'           : 15.2,
    'Stationery'              : 12,
    'Jewellery'               : 11.3,
    'Bride\'s outfit'         : 14.6,
    'Groom\'s outfit'         : 16.5,
    'OTHER'                   : 9,
}
  
  
























