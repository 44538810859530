import React, { useContext, useState, useRef, useEffect } from 'react';
import { FirebaseContext } from '../../firebaseConnect';
import mobiscroll from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import "./VendorEditForm.css";
import Agenda from "../Agenda/Agenda";
import GenericAgenda from "../Agenda/GenericAgenda";
import CustomGallery from "./CustomGallery";
import 'react-toastify/dist/ReactToastify.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { css } from "@emotion/core";
import Rating from "react-rating";
import { vendorInitialState } from '../../constants.js'
import { useHistory } from "react-router-dom";
import { normalizeSync } from 'normalize-diacritics';
const VENDORTYPES = require('../../data/vendorTypes');
const DATA = require('../../data/orderedCities');

const VendorEdit = () => {

  ///https://www.davidhu.io/react-spinners/
  const override = css`
display: flex;
align-items: center;    
margin: 0 auto;
border-color: #ec9177;
`;

  const [cities, setCities] = useState(DATA.ORDERED_CITIES);
  const eventRef = useRef(null);
  const fileInputRef = useRef(null);
  const { firebase, user, t, language, addIsLoading, removeIsLoading, resetIsLoading } = useContext(FirebaseContext)
  const id = user?.id;
  const [vendor, setVendor] = useState(user ?? vendorInitialState)
  const [profileIsNotReady, setProfileIsNotReady] = useState(false)
  const [picToRemove, setPicToRemove] = useState(null)
  let history = useHistory();

  useEffect(() => {
    setVendor(user ?? vendorInitialState)
    if (user) {
      const profileIsReady = user.hasPictures && user.publishProfile;
      setProfileIsNotReady(!profileIsReady)
    }
  }, [user])

  useEffect(() => {
    const isAuthenticated = !!user;
    if (isAuthenticated) {
      const isAdmin = !!user && user.isAdmin;
      if (isAdmin) {
        history.push(`/${language}/VendorDirectory`)
      }
    }
    else {
      history.push(`/${language}/VendorLogin/VendorEdit`)
    }
  }, [user])


  useEffect(() => {
    resetIsLoading()
  }, [])

  const [validation, setValidation] = useState({
    vendorName: null,
    activity: null,
    city: null,
    profileDescription: null,
    frenchProfileDescription: null
  })
  const [currentTab, setCurrentTab] = useState(0)

  const handleSubmit = async e => {
    e.preventDefault();
    addIsLoading('VendorEdit - handleSubmit');
    const validation = validateAllFields();

    console.error('--- 0');
    if (!validation.allValid) {
      console.error('--- 1');
      removeIsLoading('VendorEdit - handleSubmit');
      setCurrentTab(0);
      setValidation({
        ...validation
      });
      return;
    }

    console.error('--- 2');
    setValidation({
      ...validation
    });

    console.error('--- 3');
    let profileDescription = vendor.profileDescription;
    let frenchProfileDescription = vendor.frenchProfileDescription;

    if (!vendor.frenchProfileDescription || vendor.frenchProfileDescription === '') {
      frenchProfileDescription = await translateText('fr', vendor.profileDescription)
    }
    if (!vendor.profileDescription || vendor.profileDescription === '') {
      profileDescription = await translateText('en', vendor.frenchProfileDescription)
    }

    let finalPictures = [];


    if (vendor.pictures.length > 0) {
      console.error('--- 4');
      finalPictures = await firebase.uploadImages(vendor.id, vendor.pictures)
    }

    console.error('--- 5');
    const vendorRef = firebase.db.collection('vendors').doc(id);
    const doc = await vendorRef.get();
    console.error('0. ----> finalPictures', finalPictures);
    if (doc.exists) {
      console.error('----> vendor', vendor);
      const finalPicturesUrls = [...vendor.picturesUrls, ...finalPictures];
      console.error('1. ----> finalPicturesUrls', finalPicturesUrls);
      try {
        await vendorRef.update({
          ...vendor,
          frenchProfileDescription,
          profileDescription,
          pictures: [],
          picturesPreviews: [],
          picturesUrls: finalPicturesUrls,
          hasPictures: finalPicturesUrls.length > 0
        })
      } catch (error) {
        console.error('----> error', error);
      }
      removeIsLoading('VendorEdit - handleSubmit');
    }
  }

  const handleInputChange = (event) => {
    setVendor({
      ...vendor,
      [event.target.name]: event.target.value
    })
  }

  const handleSet = (name, value, instance) => {
    setVendor(previous => {
      return {
        ...previous,
        [name]: instance.getVal()
      }
    })
  }

  const handleSwitch = (name, value) => {
    setVendor(previous => {
      return {
        ...previous,
        [name]: value
      }
    })
  }

  const handleRatingChange = (name, value) => {
    setVendor({
      ...vendor,
      [name]: value
    })
  }

  const btn = [
    {
      text: t('yes', 'yes'),
      icon: 'checkmark',
      handler: function (event, inst) {
        if (picToRemove.removeFrom === 'actual') {
          const newPictures = vendor.picturesUrls.filter(p => p.src !== picToRemove.id);
          setVendor({
            ...vendor,
            picturesUrls: newPictures
          })
        }
        else {
          const newPictures = vendor.picturesPreviews.filter(p => p.src !== picToRemove.id);
          setVendor({
            ...vendor,
            picturesPreviews: newPictures
          })
        }


        inst.hide();
      }
    },
    {
      text: t('no', 'no'),
      icon: 'close',
      handler: function (event, inst) {
        inst.hide();
      }
    }
  ];
  const handleRemovePicture = (id) => {
    setPicToRemove({ id, removeFrom: 'actual' })
    eventRef.current.instance.show();
  }

  const handleRemovePicturePreview = (id) => {
    setPicToRemove({ id, removeFrom: 'preview' })
    eventRef.current.instance.show();
    fileInputRef.current.instance._$input[0].value = ''
  }

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    })
  }

  const compress = (inputFile) => {
    return new Promise((resolve, reject) => {
      const width = 763;
      const fileName = inputFile.name;
      const reader = new FileReader();
      let file = {};
      reader.onload = event => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const elem = document.createElement('canvas');
          const scaleFactor = width / img.width;
          elem.width = width;
          const height = img.height * scaleFactor;
          elem.width = width;
          elem.height = height;
          const ctx = elem.getContext('2d');
          // img.width and img.height will contain the original dimensions
          ctx.drawImage(img, 0, 0, width, height);
          ctx.canvas.toBlob((blob) => {
            file = new File([blob], fileName, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            resolve(file);
          }, 'image/jpeg', 1);
        }
        reader.onerror = reject;
      };
      reader.readAsDataURL(inputFile);
    });
  }


  const contentPhotos = () => {
    return (
      <mobiscroll.Form className="mbsc-form-grid" theme="savemydate" onSubmit={handleSubmit}>
        <div className="mbsc-grid">
          <div className="mbsc-row mbsc-justify-content-center">
            <div className="mbsc-col-sm-8 mbsc-col-md-8 mbsc-col-xl-8">
              <div className="mbsc-btn-group-block">
                <mobiscroll.Input type="file" placeholder="Select file..." accept="image/*" multiple
                  ref={fileInputRef}
                  onChange={async e => {
                    e.persist()
                    var keys = Object.keys(e.target.files);
                    const filePreviews = [];
                    const newPictures = [];
                    for (const k of keys) {
                      const file = await compress(e.target.files[k]);
                      newPictures.push(file);
                      const localUrl = URL.createObjectURL(file)
                      const finalUrl = { src: localUrl, width: 3, height: 3 }
                      filePreviews.push(finalUrl);
                    }
                    setVendor({
                      ...vendor,
                      picturesPreviews: [...vendor.picturesPreviews, ...filePreviews],
                      pictures: [...vendor.pictures, ...newPictures]
                    })
                  }}>File upload</mobiscroll.Input>
              </div>
            </div>
          </div>
          <div className="mbsc-row mbsc-justify-content-center">
            <div className="mbsc-col-sm-8 mbsc-col-md-8 mbsc-col-xl-8">
              <div className="mbsc-btn-group-block">
                {vendor.picturesPreviews?.length === 0 ? (<></>) : (<CustomGallery photos={vendor.picturesPreviews} onClick={handleRemovePicturePreview} />)}
              </div>
            </div>
          </div>
          <div className="mbsc-row mbsc-justify-content-center">
            <div className="mbsc-col-sm-4 mbsc-col-md-4 mbsc-col-xl-4">
              <div className="mbsc-btn-group-block">
                <mobiscroll.Button type='submit'>Save Images</mobiscroll.Button>
              </div>
            </div>
            <div className="mbsc-col-sm-4 mbsc-col-md-4 mbsc-col-xl-4">
              <div className="mbsc-btn-group-block">
                <mobiscroll.Button type='submit'>Select All Images</mobiscroll.Button>
              </div>
            </div>
          </div>
          <div className="mbsc-row mbsc-justify-content-center">
            <div className="mbsc-col-sm-11 mbsc-col-md-11 mbsc-col-xl-11">
              <div>
                <mobiscroll.Popup
                  ref={eventRef}
                  display="center"
                  buttons={btn}
                >
                  <div className="mbsc-align-center mbsc-padding">
                    <h4 className="md-text-center">{t('remove_picture', 'Do you want to remote this picture?')}</h4>
                  </div>
                </mobiscroll.Popup>
                {vendor.picturesUrls?.length === 0 ? (<></>) : (<CustomGallery photos={vendor.picturesUrls} onClick={handleRemovePicture} />)}
              </div>
            </div>
          </div>
        </div>
      </mobiscroll.Form>
    );
  }

  const contentAgenda = () => {
    return (
      vendor.isGoogleProfile ?
        <Agenda vendor={vendor} /> :
        <GenericAgenda vendor={vendor} />
    );
  }

  const validateAllFields = () => {
    const newValidation = {
      vendorName: null,
      activity: null,
      city: null,
      profileDescription: null,
      frenchProfileDescription: null,
      allValid: true
    }
    let validFrenchDescription = true;
    let validEnglishDescription = true;
    if (!vendor.profileDescription || vendor.profileDescription === '') {
      newValidation.profileDescription = t('This field is required', 'This field is required')
      newValidation.allValid = false;
      validEnglishDescription = false;
    }
    else if (vendor.profileDescription.length < 300) {
      newValidation.profileDescription = t('It should be at least 300 characters long', 'It should be at least 300 characters long');
      newValidation.allValid = false;
      validEnglishDescription = false;
    }
    if (!vendor.frenchProfileDescription || vendor.frenchProfileDescription === '') {
      newValidation.frenchProfileDescription = t('This field is required', 'This field is required')
      newValidation.allValid = false;
      validFrenchDescription = false;
    }
    else if (vendor.frenchProfileDescription.length < 300) {
      newValidation.frenchProfileDescription = t('It should be at least 300 characters long', 'It should be at least 300 characters long');
      newValidation.allValid = false;
      validFrenchDescription = false;
    }

    if (validFrenchDescription || validEnglishDescription) {
      newValidation.allValid = true;
      newValidation.profileDescription = ''
      newValidation.frenchProfileDescription = ''
    }

    if (!vendor.vendorName) {
      newValidation.vendorName = t('This field is required', 'This field is required')
      newValidation.allValid = false;
    }
    else if (vendor.vendorName.length < 3) {
      newValidation.vendorName = t('It should be at least 3 characters long', 'It should be at least 3 characters long');
      newValidation.allValid = false;
    }
    if (!vendor.activity || vendor.activity === '') {
      newValidation.activity = t('This field is required', 'This field is required')
      newValidation.allValid = false;
    }
    if (!vendor.city || vendor.city === '') {
      newValidation.city = t('This field is required', 'This field is required')
      newValidation.allValid = false;
    }

    if (vendor.picturesPreviews.length === 0
      && vendor.picturesUrls.length === 0
      && vendor.publishProfile) {
      newValidation.picturesUrls = t('You need to provide at least one picture if you want your profile to be listed', 'You need to provide at least one picture if you want your profile to be listed');
      newValidation.allValid = false;
    }

    return newValidation;
  }

  const translateText = async (target, text) => {
    addIsLoading('VendorEdit - translateText');
    const callable = firebase.functions.httpsCallable('translateText');
    const msg = {
      "target": target,
      'text': text
    };
    const result = await callable(msg);
    removeIsLoading('VendorEdit - translateText');
    return result.data;
  }

  const translateToEnglish = async e => {
    e.preventDefault();
    addIsLoading('VendorEdit - translateToEnglish');
    const translation = await translateText('en', vendor.frenchProfileDescription)
    setVendor(previous => {
      return {
        ...previous,
        profileDescription: translation
      }
    })
    removeIsLoading('VendorEdit - translateToEnglish');
  }

  const translateToFrench = async e => {
    e.preventDefault();
    addIsLoading('VendorEdit - translateToFrench');
    const translation = await translateText('fr', vendor.profileDescription)
    setVendor(previous => {
      return {
        ...previous,
        frenchProfileDescription: translation
      }
    })
    removeIsLoading('VendorEdit - translateToFrench');
  }
  
  const onFilter = (event, inst) => {
    const normalizeed = normalizeSync(event.filterText).toLowerCase();
    console.log('1. #########################', normalizeed);
   
    const filteredItems = DATA.ORDERED_CITIES.filter(city => city.clean_value.includes(normalizeed));
    console.log('2. #########################', filteredItems);
    setCities(filteredItems);
        
    return false;
  }

  const contentProfile = () => {
    return (
      <>
        <mobiscroll.Form className="mbsc-form-grid" theme="savemydate" onSubmit={handleSubmit}>
          <div className="mbsc-grid">
            {validation.picturesUrls &&
              <div className="mbsc-row mbsc-justify-content-center">
                <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-4">
                  <div className="mbsc-btn-group-block">
                    <div className="mbsc-err-msg-big">{validation.picturesUrls}</div>
                  </div>
                </div>
              </div>}
            {profileIsNotReady &&
              <div className="mbsc-row mbsc-justify-content-center">
                <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-4">
                  <div className="mbsc-btn-group-block">
                    <div className="mbsc-msg-msg-big">{t('success_need', '...')}</div>
                  </div>
                </div>
              </div>}
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-4">
                <mobiscroll.Input
                  labelStyle="floating"
                  name='vendorName'
                  value={vendor.vendorName ?? ''}
                  valid={!validation.vendorName}
                  errorMessage={validation.vendorName}
                  onChange={handleInputChange}
                  placeholder={t("Enter the name of your company", "Enter the name of your company")}>
                  {t('_Vendor', 'Vendor')}
                </mobiscroll.Input>
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-4 fix-wrap-validation">
                <mobiscroll.Input
                  disabled={true}
                  name='vendorEmail'
                  value={vendor.vendorEmail}
                  onChange={handleInputChange}
                  labelStyle="floating"
                  placeholder={t("Enter your email address", "Enter your email address")}>
                  {t("Email", "Email")}
                </mobiscroll.Input>
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-4  fix-wrap">
                <mobiscroll.Select
                  touchUi={false}
                  data={language.startsWith('en') ? VENDORTYPES.EN_TYPES : VENDORTYPES.FR_TYPES}
                  valid={!validation.activity}
                  errorMessage={validation.activity}
                  name='activity'
                  placeholder={t("Please select which type of service you provide", "Please select which type of service you provide")}
                  showOnTap={true} showOnFocus={true}
                  layout='liquid'
                  value={vendor.activity}
                  onSet={(value, instance) => handleSet('activity', value, instance)}>
                </mobiscroll.Select>
                {validation.activity && <span className="mbsc-err-msg">{validation.activity}</span>}
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-4 fix-wrap">
                <mobiscroll.Select
                  value={vendor.city}
                  touchUi={false}
                  filter={true}
                  onFilter={onFilter}
                  data={cities}
                  buttons= {['cancel', 'clear']}
                  data={cities}
                  name='city'
                  valid={!validation.city}
                  errorMessage={validation.city}
                  placeholder={t("Please select where your company is based", "Please select where your company is based")}
                  showOnTap={true}
                  showOnFocus={true}
                  layout='liquid'
                  onSet={(value, instance) => handleSet('city', value, instance)}>
                </mobiscroll.Select>
                {validation.city && <span className="mbsc-err-msg">{validation.city}</span>}
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center fix-wrap-validation">
              <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-4">
                <div className="mbsc-btn-group-block">
                  <mobiscroll.Button onClick={translateToFrench}>{t("Translate to French", "Translate to French")}</mobiscroll.Button>
                </div>
                <mobiscroll.Textarea rows={15}
                  name='profileDescription'
                  valid={!validation.profileDescription}
                  errorMessage={validation.profileDescription}
                  value={vendor.profileDescription}
                  onChange={handleInputChange}
                >{t("English Description", "English Description")}</mobiscroll.Textarea>
              </div>
              <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-4">
                <div className="mbsc-btn-group-block">
                  <mobiscroll.Button onClick={translateToEnglish}>{t("Translate to English", "Translate to English")}</mobiscroll.Button>
                </div>
                <mobiscroll.Textarea rows={15}
                  name='frenchProfileDescription'
                  valid={!validation.frenchProfileDescription}
                  errorMessage={validation.frenchProfileDescription}
                  value={vendor.frenchProfileDescription}
                  onChange={handleInputChange}
                >{t("French Description", "French Description")}</mobiscroll.Textarea>
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center fix-wrap-validation">
              <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-4">
                <div className="smd-test">
                  <span className="mbsc-label" title="Publier le profil?">{t("Price Rating", "Price Rating")}</span>
                  <Rating onChange={e => handleRatingChange('pricingInformation', e)}
                    initialRating={vendor.pricingInformation}
                    stop={4}
                    emptySymbol={<span className="dolar-not-checked-big"><i className="fas fa-dollar-sign"></i></span>}
                    fullSymbol={<span className="dolar-checked-big"><i className="fas fa-dollar-sign"></i></span>}
                  />
                </div>
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-4">
                <mobiscroll.Switch checked={vendor.publishProfile} name='publishProfile' onChange={e => handleSwitch('publishProfile', e.target.checked)}>{t("Publish Profile?", "Publish Profile?")}</mobiscroll.Switch>
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-4">
                <mobiscroll.Switch checked={vendor.publishCalendar} name='publishCalendar' onChange={e => handleSwitch('publishCalendar', e.target.checked)}>{t("Publish Calendar?", "Publish Calendar?")}</mobiscroll.Switch>
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-4">
                <div className="mbsc-btn-group-block">
                  <mobiscroll.Button type='submit'>{t("Save account", "Save account")}</mobiscroll.Button>
                </div>
              </div>
            </div>
          </div>
        </mobiscroll.Form >
      </>
    )
  }
  const handleProfile = (event) => {
    event.preventDefault();
    history.push(`/${language}/VendorProfile/${id}`)
  }

  const CustomTab = ({ children }) => (
    <Tab>
      <mobiscroll.Button type='button' className='vendor-edit-tab-button' flat={true} onClick={handleProfile}>{t("See my Profile", "See my Profile")}</mobiscroll.Button>
    </Tab>
  );

  CustomTab.tabsRole = 'Tab';

  return (
    <div className="container-back">
      <div className="container-back-content">
        <h1>{t('Edit your Profile', 'Edit your Profile')}</h1>
        <div className="vendor-edit">

          <Tabs selectedIndex={currentTab} onSelect={e => setCurrentTab(e)}>
            <TabList>
              <Tab selectedClassName='vendorEdit__tab--selected' className='vendor-edit-tab'>{t("Profile", "Profile")}</Tab>
              <Tab selectedClassName='vendorEdit__tab--selected' className='vendor-edit-tab'>{t("Agenda", "Agenda")}</Tab>
              <Tab selectedClassName='vendorEdit__tab--selected' className='vendor-edit-tab'>{t("Photos", "Photos")}</Tab>
              <CustomTab selectedClassName='vendorEdit__tab--selected' className='vendor-edit-tab'>{t("Test", "Test")}</CustomTab>
            </TabList>
            <TabPanel>
              {contentProfile()}
            </TabPanel>
            <TabPanel>
              {contentAgenda()}
            </TabPanel>
            <TabPanel>
              {contentPhotos()}
            </TabPanel>
            <TabPanel>
              'I am empty'
            </TabPanel>
          </Tabs>
        </div >
      </div>
    </div>
  );
}

export default VendorEdit