import React, { useContext, useState, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel';
import './VendorProfile.css';
import { useHistory } from "react-router-dom";
import buildFullPriceRating from '../Vendor/buildPriceRating'
import 'react-day-picker/lib/style.css';
import { FirebaseContext } from '../../firebaseConnect';
import { v4 as uuidv4 } from 'uuid';
import VendorContact from "../VendorContact/VendorContact";
import mobiscroll from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import buildEvents from '../Agenda/buildEvents.js'
import * as moment from 'moment';

const carrouselItem = (key, source, name) => {
  return (<Carousel.Item key={key}>
    <img className="d-block w-100 carousel-item-height-bigger" src={source} alt={name} fluid="true" />
  </Carousel.Item>);
}

const now = new Date();
const currYear = now.getFullYear();
const currMonth = now.getMonth();
const currDay = now.getDate();
const min = new Date(currYear, currMonth, currDay);
const max = new Date(currYear, currMonth + 6, currDay);

const initialState = {
  vendorName: '',
  isGoogleProfile: false,
  id: '',
  vendorEmail: '',
  activity: '',
  city: '',
  profileDescription: '',
  pricingInformation: 0,
  hasPictures: false,
  picturesUrls: [],
  pictures: [],
  picturesPreviews: [],
  calendars: []
}

const VendorProfile = (props) => {
  const id = props.match.params.id;
  const { firebase, t, language, addIsLoading, removeIsLoading } = useContext(FirebaseContext);
  const [vendor, setVendor] = useState(initialState);
  const [events, setEvents] = useState([]);
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    const getVendors = async () => {
      addIsLoading('VendorProfile - useEffect');
      const vendorRef = firebase.db.collection('vendors').doc(id)
      const doc = await vendorRef.get();
      if (doc.exists) {
        const vendor = doc.data();
        setVendor(vendor);
        const newEvents = buildEvents(t, vendor.events ?? [], moment()).map(e => {
          return {
            d: moment(e.start, "YYYY-MM-DD").toDate(), background: 'red'
          }
        });
        setEvents(newEvents);
        removeIsLoading('VendorProfile - useEffect');
      }
      else {
        history.push(`/${language}/NotFound`);
      }
    }
    getVendors()
  }, [id])

  const showCalendar = () => {
    return vendor.publishCalendar ? <mobiscroll.Calendar
      display="inline"
      type="hidden"
      controls={['calendar']}
      months={1}
      min={min}
      max={max}
      colors={events}
      yearChange={false}
      responsive={{
        small: {
          months: 1
        },
        large: {
          months: 2
        }
      }}
    /> : <></>
  }


  return (
    <div className='container-back'>
      <div className="container-back-content">
        <div>
          <h1 className="page-title">{vendor.vendorName}</h1>
          <h6 className="page-sub-title">
            {buildFullPriceRating(vendor.pricingInformation)}
          - {vendor.activity} – {vendor.city}</h6>
        </div>
        <div className='vendor-profile-main'>
          <div className="mbsc-grid">
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-8">
                <Carousel>
                  {
                    vendor.picturesUrls.map(url => {
                      return carrouselItem(uuidv4(), url.src, url.src);
                    })
                  }
                </Carousel>
              </div>
              <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-4">
                <VendorContact vendor={vendor} />
              </div>
            </div>
          </div>
        </div>
        <div className="mbsc-grid">
          <div className="mbsc-row mbsc-justify-content-center">
            <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-8 vendor-profile-about">
              <h4 className='vendor-profile-about-header'>{t("About", "About")} {vendor.vendorName}</h4>
              <div className='vendor-description'>
                {language.startsWith('en') ? vendor.profileDescription : vendor.frenchProfileDescription}
              </div>
            </div>
            <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-4 vendor-profile-agenda">
              {showCalendar()}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default VendorProfile;
