import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../../firebaseConnect';
import "./VendorLogin.css";
import { useHistory } from "react-router-dom";
import mobiscroll from '@mobiscroll/react';
import { v4 as uuidv4 } from 'uuid';
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";

const SendInvitation = () => {
  const { firebase, t, user, language, addIsLoading, removeIsLoading } = useContext(FirebaseContext);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  let history = useHistory();

  useEffect(() => {
    const isAuthenticated = !!user;
    if (!isAuthenticated) {
      history.push(`/${language}/VendorLogin/SendInvitation`)
    }
  }, [user])

  const handleEmailChange = event => {
    setEmail(event.target.value)
  }

  const handleNameChanged = event => {
    setName(event.target.value)
  }

  const sendEmail = (id) => {
    const link = `https://www.jolirendezvous.ca/${language}/VendorCreateAccount/`;

    const callable = firebase.functions.httpsCallable('sendEmailSES');
    const html = `  <h4><strong>${t("hello", "Hello ")}${name}</strong></h4>
    <p>${t("Invit_Paragraph_1", "...")}</p>
    <p>${t("Invit_Paragraph_2", "...")}</p>
    <p>${t("Invit_Paragraph_3", "...")}</p>
    <p>${t("Invit_Paragraph_4", "...")}</p>
    <p><a href=${link}${id}>${t("Create an Account", "Create an Account")}</a></p>
    <h6>${t("Invit_Link_Explanation", "Invit_Link_Explanation")}</h6>
    <p>${t("Invit_Signature", "...")}</p>`;
    const msg = {
      to: email,
      from: 'leonardonsantana@gmail.com',
      subject: t("InvitTitle", "InvitTitle"),
      html: html,
    };

    console.log('1. ---------------->', msg);
    return callable(msg).then(res => console.log('******* res', res)).catch(err => console.log(err))
  }

  const handleSendInvitation = async () => {
    addIsLoading('VendorEdit - handleSendInvitation');

    const id = uuidv4();
    await firebase.db.collection('invitations').doc(id).set({
      id: id,
      email: email,
      name: name
    })
    sendEmail(id);

    removeIsLoading('VendorEdit - handleSendInvitation');
    setEmail('');
    setName('');

    mobiscroll.toast({
      message: t('Invitation has been sent', 'Invitation has been sent')
    });
  }

  return (
    <div className="container-back">
      <div className="container-back-content">
        <h1 className="page-title">{t("Send Invitation", "Send Invitation")}</h1>
        <mobiscroll.Form className="mbsc-form-grid" theme="savemydate">
          <div className="mbsc-grid">
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-4">
                <div className="mbsc-btn-group-block">
                  <mobiscroll.Input
                    labelStyle="floating"
                    name='name'
                    value={name}
                    onChange={handleNameChanged}
                    placeholder={t("Enter the name of the vendor", "Enter the name of the vendor")}>
                    {t('_Vendor', 'Vendor')}
                  </mobiscroll.Input>
                  <mobiscroll.Input
                    name='email'
                    value={email}
                    onChange={handleEmailChange}
                    labelStyle="floating" placeholder={t("Enter your email address", "Enter your email address")}>{t("Email", "Email")}</mobiscroll.Input>
                </div>
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-5">
                <div className="mbsc-btn-group-block">
                  <div className="sendinvitation-message">
                    <h4><strong>{t("hello", "Hello ")}{name}</strong></h4>
                    <p>{t("Invit_Paragraph_1", "...")}</p>
                    <p>{t("Invit_Paragraph_2", "...")}</p>
                    <p>{t("Invit_Paragraph_3", "...")}</p>
                    <p>
                      <a href={`https://www.jolirendezvous.ca/${language}/VendorCreateAccount/`}>{t("Create an Account", "Create an Account")}
                      </a>
                    </p>
                    <span>
                      <small>{t("Invit_Link_Explanation", "Invit_Link_Explanation")} {email}</small>
                    </span>
                    <p>{t("Invit_Paragraph_4", "...")}</p>
                    <p>{t("Invit_Signature", "...")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-4">
                <div className="mbsc-btn-group-block">
                  <mobiscroll.Button className="create-account-button" onClick={handleSendInvitation}>{t("Invitation", "Invitation")}</mobiscroll.Button>
                </div>
              </div>
            </div>
          </div>
        </mobiscroll.Form>
      </div>
    </div>
  );
}

export default SendInvitation;