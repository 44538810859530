import React, { useContext } from "react";
import Carousel from 'react-bootstrap/Carousel';
import './Vendor.css';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import buildFullPriceRating from './buildPriceRating';
import { FirebaseContext } from '../../firebaseConnect';
import mobiscroll from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
const VENDORTYPES = require('../../data/vendorTypes');

const carrouselItem = (key, source, name) => {
  return (<Carousel.Item key={key}>
    <img className="d-block w-100 carousel-item-height" src={source} alt={name} fluid="true" />
  </Carousel.Item>);
}

const Vendor = ({ id, vendorName, activity, city, profileDescription, frenchProfileDescription, pricingInformation, picturesUrls = [], website, facebook, instagram, isAdmin }) => {
  let history = useHistory();
  const { t, language, firebase, addIsLoading, removeIsLoading } = useContext(FirebaseContext)

  const handleDelete = (event) => {
    event.preventDefault();
    mobiscroll.confirm({
      title: t('Confirm Deletion', 'Confirm Deletion'),
      message: t('Are you sure you want to unpublish this vendor?', 'Are you sure you want to unpublish this vendor?'),
      okText: t('Delete', 'Delete'),
      callback: async (res) => {
        if (res) {
          addIsLoading('Vendor - handleDelete');
          await firebase.db.collection('vendors').doc(id).update({ publishProfile: false });
          removeIsLoading('Vendor - handleDelete');
          mobiscroll.toast({
            message: t('Vendor has been unpublished', 'Vendor has been unpublished')
          });
        }
      }
    });
  }

  const handleProfile = (event) => {
    event.preventDefault();
    history.push(`/${language}/VendorProfile/${id}`)
  }


  const calculateVendorValues = (vendorValue) => {
    var vendor = language.startsWith('en') ? VENDORTYPES.EN_TYPES.filter(v=>v.value === vendorValue) : VENDORTYPES.FR_TYPES.filter(v=>v.value === vendorValue);
    return vendor[0]?.text;
  }

  return (
    <div className='vendor-container'>
      <div className="mbsc-grid">
        <div className="mbsc-row mbsc-justify-content-center no-gutters">
          <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-3">
            <Carousel>
              {
                picturesUrls.map(url => {
                  return carrouselItem(uuidv4(), url.src, url.src);
                })
              }
            </Carousel>
          </div>
          <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-9">
            <div className="vendor-body">
              <h5 className="vendor-card-title" onClick={handleProfile}>{vendorName}</h5>
              <div onClick={handleProfile}><h6>
              {buildFullPriceRating(pricingInformation)} - {calculateVendorValues(activity)} – {city}</h6></div>
              {/* <h6>{facebook && <a href={facebook} target="_blank" className="fa fa-facebook link"></a>}
                {instagram && facebook && <> - </>}
                {instagram && <a href={instagram} target="_blank" className="fa fa-instagram link"></a>}
                {website && instagram && <> - </>}
                {website && <> <a href={website} target="_blank" className="mbsc-ic mbsc-ic-home link"></a></>}</h6> */}
              <div className='module overflow' onClick={handleProfile}>
                <p>{language.startsWith('en') ? profileDescription : frenchProfileDescription}</p>
              </div>
            </div>
            <div className="mbsc-grid">
              <div className="mbsc-row mbsc-justify-content-end no-gutters">
                <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-6 vendor-align-end">
                  {isAdmin ? <mobiscroll.Button onClick={handleDelete} className="vendor_alert" icon="remove"></mobiscroll.Button> : <></>}
                  <mobiscroll.Button onClick={handleProfile}>{t('Profile', 'Profile')}</mobiscroll.Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
}

export default Vendor;
