import React, { useContext, useState, useEffect, useRef } from "react";
import mobiscroll from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { FirebaseContext } from '../../firebaseConnect';
import contactPicure from './contactPicture.jpg';
import ReCAPTCHA from "react-google-recaptcha";
import './Contact.css';
import FAQ from './faq.js';

const intialState = {
  name: null,
  email: null,
  message: null,
  captcha: null
}

const Contact = () => {
  const { firebase, t, addIsLoading, removeIsLoading } = useContext(FirebaseContext)
  const [manager, setManager] = useState({})
  const [formValue, setFormValue] = useState(intialState)

  useEffect(() => {
    const getManager = async () => {
      const vendorRef = firebase.db.collection('manager').doc('lDw9SjYtLcAWr0UwMlDI')
      const doc = await vendorRef.get();
      if (doc.exists) {
        const vendor = doc.data()
        setManager({
          email: vendor.email,
          name: vendor.name
        })
      }
      removeIsLoading('Contact - useEffect');
    }
    getManager()
  }, [])

  const handleSubmit = e => {
    e.preventDefault();
    sendEmail()
  }

  const onChange = (value) => {
    console.log("Captcha value:", value);
    setFormValue(previous => {
      return {
        ...previous,
        captcha: value
      }
    })
  }



  const handleInputChange = event => {
    event.persist()
    setFormValue(previous => {
      return {
        ...previous,
        [event.target.name]: event.target.value
      }
    })
  }


  const eventRef = useRef(null);
  const btn = [{
    text: 'Ok',
    handler: 'set'
  }
  ];

  const sendEmail = () => {
    addIsLoading('Contact - sendEmail');
    const callable = firebase.functions.httpsCallable('sendEmailSES');
    const msg = {
      to: manager.email,
      bcc: formValue.email,
      from: 'contact@jolirendezvous.com',
      subject: `Contact JoliRendezvous.ca - You received a message from ${formValue.name}`,
      text: `Hello ${manager.name}\nYou have a new message from:\n Name:${formValue.name}\nEmail:${formValue.email}\nMessage:${formValue.message}`,
      html: `<p>    Hello <strong>${manager.name}</strong></p><p>    You have a new message from:  <strong>${formValue.name}</strong>    <ul>        <li>Email: <strong></strong>${formValue.email}</li><li>Message: <strong>${formValue.message}</strong></li>      </ul></p>`,
    };

    return callable(msg).then(res => {
      removeIsLoading('Contact - sendEmail');
      setFormValue(intialState);
      eventRef.current.instance.show();
    }
    ).catch(err => console.log('#### err', err))
  }

  return (
    <div className="container-back">
      <div className="container-back-content">
        <h1 className="page-title">{t('Contact Vendor', 'Contact Vendor')}</h1>
        <mobiscroll.Form className="mbsc-form-grid" theme="savemydate" onSubmit={handleSubmit}>
          <mobiscroll.Popup
            ref={eventRef}
            display="center"
            buttons={btn}
          >
            <div className="mbsc-align-center mbsc-padding">
              <h4 className="md-text-center">{t('email_sent', 'Your email has been sent!')}</h4>
            </div>
          </mobiscroll.Popup>
          <div className="mbsc-grid">
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-6 mbsc-col-md-6 mbsc-col-xl-6">
                <div className="mbsc-btn-group-block">
                  <img src={contactPicure} className="img-thumbnail" alt='it was always you - wedding contact' />
                </div>
              </div>
              <div className="mbsc-col-sm-6 mbsc-col-md-6 mbsc-col-xl-6">
                <div className="mbsc-row mbsc-justify-content-center">
                  <div className="mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-xl-12">
                    <div className="mbsc-btn-group-block">
                      <mobiscroll.Input placeholder="Enter your name" name="name" onChange={handleInputChange} >
                        {t('name', 'Name')}
                      </mobiscroll.Input>
                    </div>
                  </div>
                  <div className="mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-xl-12">
                    <div className="mbsc-btn-group-block">
                      <mobiscroll.Input placeholder="Enter your email" type="email" name="email" onChange={handleInputChange} >
                        {t('Email', 'Email')}
                      </mobiscroll.Input>
                    </div>
                  </div>
                  <div className="mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-xl-12">
                    <div className="mbsc-btn-group-block">
                      <mobiscroll.Textarea name='message' onChange={handleInputChange}>Your Message</mobiscroll.Textarea>
                    </div>
                  </div>
                </div>
                <div className="mbsc-row mbsc-justify-content-center">
                  <div className="mbsc-col-sm-10 mbsc-col-md-8 mbsc-col-xl-8">
                    <div className="mbsc-btn-group-block">
                      <div className="vendor-contact-captcha">
                        {/* https://www.google.com/recaptcha/admin/site/433185466/setup */}
                        <ReCAPTCHA
                          sitekey="6Lce49EZAAAAAJ_5xMziZPmCvkwNq9QuGXsSigsK"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mbsc-row mbsc-justify-content-center">
                  <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-5">
                    <div className="mbsc-btn-group-block">
                      <mobiscroll.Button type='submit'  disabled={formValue.message === null || formValue.captcha === null || formValue.email === null || formValue.name === null }  >{t('send_email', 'Send Email')}</mobiscroll.Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mobiscroll.Form>
        {FAQ()}
      </div>
    </div >
  )
}

export default Contact;
