import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import VendorEdit from '../VendorEdit/VendorEdit';
import VendorDirectory from '../VendorDirectory';
import VendorLogin from '../VendorLogin';
import ConfirmAccount from '../VendorLogin/ConfirmAccount';
import VendorCreateAccount from '../VendorLogin/VendorCreateAccount';
import SendInvitation from '../VendorLogin/SendInvitation';
import Contact from '../Contact/Contact';
import VendorProfile from '../VendorProfile';
import VendorContact from '../VendorContact';
import NotFound from '../NotFound/NotFound';
import LoginRoute from './LoginRoute'
import About from '../pages/About';
import Privacy from '../pages/privacy';
import CookiePrivacy from '../pages/cookiePrivacy';
import Spinner from '../Spinner/Spinner';
import { locale } from '../../constants';
//LAZY loading .... need also to do the Suspense / Spinner
// const About = React.lazy(() => import('../pages/About'));

const Footer = lazy(() => import('../Footer'));
const NavigationBar = lazy(() => import('../NavigationBar'));
const Search = lazy(() => import('../Search/Search'));

const AppRouter = ({language}) => {
    return (
        <Suspense fallback={<Spinner />}>
            <div className="main-height">
                <NavigationBar />
                <Switch>
                    <Route exact path="/">
                        <Redirect to={`/${language}`} />
                    </Route>
                </Switch>
                <Switch>
                    <Route exact path={locale} component={Search} />
                    <Route path={`${locale}/About`} component={About} />
                    <Route path={`${locale}/Privacy`} component={Privacy} />
                    <Route path={`${locale}/CookiePrivacy`} component={CookiePrivacy} />
                    <Route path={`${locale}/VendorDirectory`} component={VendorDirectory} />
                    <Route path={`${locale}/VendorLogin/:redirectTo?`} component={VendorLogin} />
                    <Route path={`${locale}/Contact`} component={Contact} />
                    <Route path={`${locale}/VendorProfile/:id`} component={VendorProfile} />
                    <Route path={`${locale}/VendorContact/:id`} component={VendorContact} />
                    <Route path={`${locale}/Spinner`} component={Spinner} />
                    <Route path={`${locale}/VendorCreateAccount/:id`} component={VendorCreateAccount} />
                    <Route path={`${locale}/SendInvitation`} component={SendInvitation} />
                    <Route path={`${locale}/VendorEdit`} component={VendorEdit} />
                    <Route path={`${locale}/ConfirmAccount/:email`} component={ConfirmAccount} />
                    <Route component={NotFound} />
                </Switch>
                <Footer />
            </div>
        </Suspense>
    )
};

export default AppRouter;
