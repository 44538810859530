import React, { useContext} from 'react';
import aboutPic1 from './images/dream_it_yourself-30-min.jpg';
import aboutPic2 from './images/dream_it_yourself-40-min.jpg';
import aboutPic3 from './images/dream_it_yourself-46-min.jpg';
import './about.css';
import { FirebaseContext } from '../../firebaseConnect';

export default () => {
    const { t } = useContext(FirebaseContext)
    return (
        <div className="container-back">
            <div className="container-back-content">
                <h1 className="page-title-about">
                    <div className="keep-in-line bold">
                        {t('about_us_1', 'About Us')}
                    </div>
                    {t('about_us_2', '...')}
                </h1>
                <div className="about-title bold first-title">
                    {t('About title 1', '...')}
                </div>
                <div className="about-layout text-left">
                    <div className="pics pic-right">
                        <img src={aboutPic1} alt="Pic 1"/>
                    </div>
                    <div className="about-text">
                        <p></p>
                        {t('About text 1-1', '...')}
                        <p></p>
                        {t('About text 1-2', '...')}
                        <p></p>
                        {t('About text 1-3', '...')}
                        <p></p>
                        {t('About text 1-4', '...')}
                        <p></p>
                        {t('About text 1-5', '...')}
                    </div>
                </div>
                <div className="about-layout text-right">
                    <div className="pics pic-left">
                        <img src={aboutPic2} alt="Pic 2"/>
                    </div>
                    <div className="about-text">
                        <div className="about-title bold">
                            {t('About title 2', '...')}
                        </div>
                        <p></p>
                        {t('About text 2-1', '...')}<a className="about-link-color" href='https://dreamityourself-montreal.com/en/home/' target='_blank'> DREAM IT YOURSELF’s </a>{t('About text 2-1-1', '...')}
                        <p></p>
                        {t('About text 2-2', '...')}
                        <p></p>
                        {t('About text 2-3', '...')}
                    </div>
                </div>
                <div className="about-layout text-left">
                    <div className="pics pic-right">
                        <img src={aboutPic3} alt="Pic 3"/>
                    </div>
                    <div className="about-text">
                        <div className="about-title bold">
                            {t('About title 3-1', '...')}
                            <p></p>
                            {t('About title 3-2', '...')}
                        </div>
                        <p></p>
                        {t('About text 3-1', '...')}<a className="about-link-color"  href='https://dreamityourself-montreal.com/en/home/' target='_blank'>DREAM IT YOURSELF</a>{t('About text 3-1-1', '...')}
                        <p></p>
                        {t('About text 3-2', '...')}<a className="about-link-color" href='https://dreamityourself-montreal.com/en/home/' target='_blank'>DREAM IT YOURSELF</a>{t('About text 3-2-1', '...')}<a className="about-link-color" href='https://dreamityourself-montreal.com/en/home/' target='_blank'>DREAM IT YOURSELF</a>{t('About text 3-2-2', '...')}
                        <p></p>
                        {t('About text 3-3', '...')}
                    </div>
                </div>
            </div>
        </div>
    )
}
