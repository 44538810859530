import React, { useContext, useEffect } from 'react';
import { FirebaseContext } from '../../firebaseConnect';
import "./ConfirmAccount.css";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";

const ConfirmAccount = (props) => {
    const { firebase, t } = useContext(FirebaseContext)
    const email = props.match.params.email;
    
    useEffect(() => {
        const effect = async () => {
            const vendorRef = firebase.db.collection('vendors').where("vendorEmail", "==", email).limit(1)
            const querySnapshot = await vendorRef.get()

            querySnapshot.forEach(doc => {
                const data = doc.data()
                doc.ref.update({
                    ...data,
                    emailConfirmed: true
                }).then(() => console.log('saved'))
            })
           
        }

        effect();
    }, []);

    return (
        <div className="container-back">
            <div className="container-back-content-full">
                <h1>{t("Your email has been confirmed.", "Your email has been confirmed.")}</h1>
                <div className="confirm-account-main">
                    <div>{t("hello", "Hello, ")}</div>
                    {email}
                    <div>{t("Your email has been confirmed and your account is ready to be used.", "Your email has been confirmed and your account is ready to be used.")}</div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmAccount;