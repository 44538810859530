import React, { useEffect, useContext, useState } from 'react';
import mobiscroll from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { FirebaseContext } from '../../firebaseConnect';
import { v4 as uuidv4 } from 'uuid';
import "./Agenda.css"
import Spinner from '../Spinner/Spinner';
import { RRule, rrulestr } from 'rrule'

const moment = require('moment')
const getUuid = require('uuid-by-string')

const initialState = {
    allCalendars: [],
    selectedCalendarId: undefined,
    eventColor: 'blue',
    events: [],
    firstDay: new Date(),
    lastDay: new Date(),
    isLoadingCalendars: true,
    isLoadingEvents: true
}

const Agenda = ({ vendor }) => {
    const { firebase, gapiClient, addIsLoading, removeIsLoading, t } = useContext(FirebaseContext)

    const [calendarEvents, setcalendarEvents] = useState([]);
    const [state, setState] = useState({
        ...initialState,
        selectedCalendarId: vendor.calendar?.id,
        eventColor: vendor.calendar?.backgroundColor ?? 'blue'
    });

    const generateCalendarId = (c, vendor) => {
        return `${uuidv4()}-${getUuid(c.id)}-${vendor.id}`;
    }

    const getCalendars = async () => {
        if (gapiClient) {
            await gapiClient.load('calendar', 'v3')
            const calendarResponse = await gapiClient.calendar.calendarList.list({ 'fields': 'items(backgroundColor,description,id,summary)' })
            const calendars = calendarResponse.result.items.map(c => {
                return {
                    uuid: generateCalendarId(c, vendor),
                    ...c
                }
            });
            return calendars.filter(r => !r.id.includes('group.v.calendar.google.com'));
        }
        else {
            console.log("Error: gapiClient not loaded");
        }
    }

    const handleLoadClick = async () => {
        const year = state.firstDay.getFullYear();
        const month = state.firstDay.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 1);

        const calendars = await getCalendars();
        const events = await getEventList(calendars, firstDay, lastDay, state.selectedCalendarId);

        setState(prevState => {
            return {
                ...prevState,
                events: events.newEvents,
                syncToken: events.syncToken,
                allCalendars: calendars,
                firstDay,
                lastDay,
                selectedCalendarId: state.selectedCalendarId,
                isLoadingEvents: false
            }
        });
        setcalendarEvents(events.calendarEvents)
    }

    const getEventList = async (calendars, firstDay, lastDay, selectedCalendarId) => {
        const newEvents = [];
        let calendarEvents = [];
        let syncToken;
        if (selectedCalendarId) {
            const calendar = calendars.find(c => c.id === selectedCalendarId);
            if (calendar) {
                let pageToken;
                do {
                    const response = await gapiClient.calendar.events.list({
                        calendarId: selectedCalendarId,
                        timeMin: firstDay.toISOString(),
                        pageToken: pageToken
                    });
                    const color = calendar.backgroundColor;
                    const events = response.result.items;
                    events.forEach(e => {
                        if (e.status === 'confirmed'){
                            newEvents.push(getEventProps(e, selectedCalendarId, color))
                        }
                    })
                    pageToken = response.result.nextPageToken;
                    syncToken = response.result.nextSyncToken;
                } while (pageToken);

                calendarEvents = buildRecurringEvents(newEvents, firstDay, lastDay)
            }
        }
        return { newEvents, calendarEvents, syncToken };
    }

    const buildRecurringEvents = (newEvents, firstDay, lastDay) => {
        let calendarEvents = [];
        calendarEvents = newEvents.filter(e => e.recurring === null && e.recurringEventId === null);
        const exclusions = newEvents.filter(e => e.recurringEventId !== null);

        const recurringEvents = newEvents.filter(e => e.recurring !== null
            && !exclusions.some(ex => ex.recurringEventId === e.id));

        const matchedExclusionEvents = newEvents.filter(e => exclusions.some(ex => ex.recurringEventId === e.id));

        for (const event of recurringEvents) {
            const recurring = `DTSTART:${moment(event.start).format('YYYYMMDD')}T${moment(event.start).format('HHmmss')}Z\n${event.recurring};UNTIL=${moment(lastDay).utc().format('YYYYMMDD')}T${moment(lastDay).format('HHmmss')}Z`;
            const rrule = RRule.fromString(recurring);
            const newE = rrule.all().map(a => {
                return {
                    start: a,
                    end: a,
                    text: event.text,
                    calendarId: event.calendarId,
                    color: event.color
                };
            })
            calendarEvents = [...calendarEvents, ...newE]
        }

        for (const event of matchedExclusionEvents) {
            const exclusionDates = [];
            const lol = exclusions.filter(e => e.recurringEventId === event.id);
            for (const exclusionDate of lol) {
                exclusionDates.push(`EXDATE:${moment(exclusionDate.start).format('YYYYMMDD')}T${moment(exclusionDate.start).format('HHmmss')}Z`)
            }
            const exclustionDateString = exclusionDates.join('\n');
            const recurring = `DTSTART:${moment(event.start).format('YYYYMMDD')}T${moment(event.start).format('HHmmss')}Z\n${exclustionDateString}\n${event.recurring};UNTIL=${moment(lastDay).utc().format('YYYYMMDD')}T${moment(lastDay).format('HHmmss')}Z`;
            const rrule = rrulestr(recurring);
            const newE = rrule.all().map(a => {
                return {
                    start: a,
                    end: a,
                    text: event.text,
                    calendarId: event.calendarId,
                    color: event.color
                };
            })
            calendarEvents = [...calendarEvents, ...newE]
        }


        return calendarEvents.filter(e => e.start >= firstDay);
    }
    const getEventProps = (event, calendarId, color) => {
        const recurringRule = (event.recurrence ?? [])[0];

        let start = null;
        if (event.originalStartTime) {
            start = event.originalStartTime.dateTime;
        } else {
            start = event.start.date || event.start.dateTime;
        }

        let end = null;
        if (event.end) {
            end = event.end.date || event.end.dateTime;
        }

        return {
            id: event.id,
            start: start,
            end: end,
            text: event.summary || t('agenda_no_title', 'No Title'),
            calendarId: calendarId,
            color: color ?? 'red',
            recurring: recurringRule ? recurringRule : null,
            recurringEventId: event.recurringEventId ?? null,
            status: event.status
        };
    }

    useEffect(() => {
        const initClient = async () => {
            await handleLoadClick()
        }
        initClient();
    }, [])

    const onPageChange = async event => {
        const firstDay = event.firstDay;

        const year = firstDay.getFullYear();
        const month = firstDay.getMonth();
        const lastDay = new Date(year, month + 1, 1);
        if (state.firstDay.getTime() === firstDay.getTime())
            return;

        const calendarEvents = buildRecurringEvents(state.events, firstDay, lastDay)
        setState(prevState => {
            return {
                ...prevState,
                firstDay,
                lastDay
            }
        })
        setcalendarEvents(calendarEvents)
    }

    const onChange = async event => {
        event.persist();
        const calendarId = event.target.value;
        //TODO: change first and last day to day on the calendar!!
        const year = state.firstDay.getFullYear();
        const month = state.firstDay.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 1);

        const events = await getEventList(state.allCalendars, firstDay, lastDay, calendarId);
        const calendar = state.allCalendars.find(c => c.id === calendarId);
        setState(prevState => {
            return {
                ...prevState,
                syncToken: events.syncToken,
                events: events.newEvents,
                selectedCalendarId: calendarId,
                eventColor: calendar.color,
                isLoadingEvents: false
            }
        })
        setcalendarEvents(events.calendarEvents)
    }

    ///****** channel_id: 1e6f8ba7-d405-4aa7-9286-cc23c0f038d2-973ad0fe-ba57-5aef-96ec-63fb1864042b-XLRjmDjtPYXprTeRKoSJMvYIHOD2
    //****** resource_id: yXlwAedj-fcvcFRjVcBpxvRzDWE
    const stop = async calendar => {
        const responde2 = await gapiClient.calendar.channels.stop({
            id: '1e6f8ba7-d405-4aa7-9286-cc23c0f038d2-973ad0fe-ba57-5aef-96ec-63fb1864042b-XLRjmDjtPYXprTeRKoSJMvYIHOD2',
            resourceId: 'yXlwAedj-fcvcFRjVcBpxvRzDWE'

        })
    }

    const handleStop = async (calendar) => {
        if (calendar) {
            if (calendar.uuid && calendar.resourceId) {
                try {
                    const response = await gapiClient.calendar.channels.stop({
                        id: calendar.uuid,
                        resourceId: calendar.resourceId
                    })
                } catch (error) {
                    console.log('5!!!!!', error)
                }
            }
        }
    }

    const watch = async (calendar, time) => {
        try {
            const response = await gapiClient.calendar.events.watch({
                id: calendar.uuid,
                calendarId: calendar.id,
                token: uuidv4(),
                type: 'web_hook',
                address: 'https://jolirendezvous.ca/receiveCalendarUpdate',
                expiration: time
            })
            return response.result.resourceId;
        } catch (error) {
            console.error(error)
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        addIsLoading('Agenda - handleSubmit');
        if (vendor) {
            const vendorRef = firebase.db.collection('vendors').doc(vendor.id)
            const doc = await vendorRef.get()
            let resourceId = '';
            var date = new Date();
            date.setDate(date.getDate() + 36500);
            if (state.selectedCalendarId !== vendor.calendar?.id) {
                const calendar = state.allCalendars.find(c => c.id === state.selectedCalendarId);
                if (calendar) {
                    await handleStop(vendor.calendar);
                    resourceId = await watch(calendar, date.getTime());
                    if (doc.exists) {
                        await vendorRef.update({
                            ...vendor,
                            calendar: {
                                ...calendar,
                                syncToken: state.syncToken,
                                resourceId
                            },
                            events: state.events
                        })
                    }
                }
                removeIsLoading('Agenda - handleSubmit')
            }
        }
    }


    return (
        <>
            <mobiscroll.Button onClick={stop}>stop</mobiscroll.Button>

            <mobiscroll.Form theme="savemydate" onSubmit={handleSubmit}>
                <div className="mbsc-grid">
                    {(state.isLoadingCalendars && state.isLoadingEvents) ? (
                        <Spinner />
                    )
                        : (
                            <>
                                <div className="mbsc-row">
                                    <div className="mbsc-col-sm-12 mbsc-col-md-4">
                                        <mobiscroll.FormGroup>
                                            <div className="agenda-divider">
                                                <mobiscroll.FormGroupTitle>{t('agenda_choose_your_calendar', 'Choose your calendar to share with us')}</mobiscroll.FormGroupTitle>
                                            </div>
                                            <mobiscroll.FormGroupContent>
                                                {state.allCalendars.map((calendar, index) => {
                                                    return (
                                                        <mobiscroll.Radio name="group" value={calendar.id} key={calendar.id} checked={state.selectedCalendarId === calendar.id} onChange={onChange}>
                                                            {calendar.summary}
                                                            <span className="mbsc-desc">{calendar.description}</span>
                                                        </mobiscroll.Radio>
                                                    );
                                                })}
                                            </mobiscroll.FormGroupContent>
                                        </mobiscroll.FormGroup>
                                    </div>
                                    <div className="mbsc-col-sm-12 mbsc-col-md-8">
                                        <mobiscroll.FormGroup>
                                            <div className="agenda-divider">
                                                <mobiscroll.FormGroupTitle>{t('agenda_events_in_calendars', 'Events in selected calendars')}</mobiscroll.FormGroupTitle>
                                            </div>
                                            <mobiscroll.Eventcalendar
                                                theme="savemydate"
                                                display="inline"
                                                view={{
                                                    calendar: {
                                                        labels: true
                                                    }
                                                }}
                                                onPageChange={onPageChange}
                                                data={calendarEvents}
                                            />
                                        </mobiscroll.FormGroup>
                                    </div>
                                </div>
                                <div className="mbsc-row mbsc-justify-content-center">
                                    <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-5">
                                        <div className="mbsc-btn-group-block">
                                            <mobiscroll.Button type='submit'>{t('agenda_share', 'Share Calendars')}</mobiscroll.Button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </mobiscroll.Form>
        </>
    )
}

export default Agenda;