import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../../firebaseConnect';
import "./VendorLogin.css";
import { useHistory } from "react-router-dom";
import mobiscroll from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";

const VendorCreateAccount = (props) => {
  const id = props.match.params.id;
  const { firebase, t, addIsLoading, removeIsLoading, language } = useContext(FirebaseContext);
  const history = useHistory();
  const [email, setEmail] = useState('your email');
  const [password, setPassword] = useState('');

  const [validation, setValidation] = useState({
    errorMessage: null
  });

  useEffect(() => {
    const effect = async () => {
      if (id) {
        addIsLoading('VendorCreateAccount - useEffect');
        const invitationRef = firebase.db.collection('invitations').doc(id);
        const invitationDoc = await invitationRef.get();
        if (invitationDoc.exists) {
          const invitation = invitationDoc.data();
          setEmail(invitation.email.trim());
        }
        else {
          history.push(`/${language}/NotFound`)
        }
        removeIsLoading('VendorCreateAccount - useEffect');
      }
    }

    effect();
  }, [firebase.db, history, id, language])

  const emailChange = event => {
    setEmail(event.target.value.trim())
  }

  const passwordChange = event => {
    setPassword(event.target.value)
  }

  const sendEmail = () => {
    const callable = firebase.functions.httpsCallable('sendEmailSES');
    const msg = {
      to: email,
      from: 'contact@jolirendezvous.com',
      subject: t("successfully_created", "successfully_created"),
      html: `<p>${t("hello", "Hello ")}<strong>${email}</strong></p><p>${t("successfully_created", "successfully_created")}</p><p><a href="https://www.jolirendezvous.ca/${language}/ConfirmAccount/${email}">${t("Confirm your account", "Confirm your account")}</a></p>`,
    };
    return callable(msg).then(res =>    console.log('--------->sendEmail<--------', res)).catch(err => console.error('--------->sendEmail<--------', err))
  }

  const handleEmailLogin = () => {
    firebase.auth.createUserWithEmailAndPassword(email.trim(), password)
      .then(e => {
        sendEmail().then(history.push(`/${language}/VendorEdit`));
      }).catch(function (error) {
        var errorMessage = t(error.message, error.message);
        setValidation(previous => {
          return {
            ...previous,
            errorMessage
          }
        })
      });
  }

  return (
    <div className="container-back">
      <div className="container-back-content-full">
        <h1 className="page-title">{t("Create an Account", "Create an Account")}</h1>
        <mobiscroll.Form className="mbsc-form-grid" theme="savemydate">
          <div className="mbsc-grid">
            {validation.errorMessage && <span className="mbsc-err-msg">{validation.errorMessage}</span>}
            <div className="mbsc-row mbsc-justify-content-center">
            <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-4">
                <mobiscroll.Input
                  disabled={true}
                  name='vendorEmail'
                  value={email}
                  onChange={emailChange}
                  labelStyle="floating" 
                  placeholder={t("Enter your email address", "Enter your email address")}>
                  {t("Email", "Email")}
                  </mobiscroll.Input>
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-4">
                <mobiscroll.Input
                  name='password'
                  value={password}
                  type="password" passwordToggle
                  onChange={passwordChange}
                  labelStyle="floating" placeholder="Enter your Password">{t("Password", "Password")}</mobiscroll.Input>
                <div className="mbsc-btn-group-block">
                  <mobiscroll.Button className="create-account-button" onClick={handleEmailLogin}>{t("Create account", "Create account")}</mobiscroll.Button>
                </div>
              </div>
            </div>
          </div>
        </mobiscroll.Form>
      </div>
    </div>
  );
}

export default VendorCreateAccount;