import { rrulestr } from 'rrule'
import {DateTime} from 'luxon';
import * as moment from 'moment'


const generateEventsToShow = (t, recurringToSave, selectedDate, daysOfWeek, start, end) => {
    start = start ?? moment(selectedDate).startOf('month').subtract(10, 'days');
    end = end ?? moment(selectedDate).endOf('month').add(10, 'days');
    const newEvents = [];
    var recurringToSaveArray = recurringToSave.split('\n');
    const total = recurringToSaveArray.length;
    let count = 0;
    const finalRecurringArray = [`DTSTART:${moment(start).utc().format('YYYYMMDD')}`];
    for (const item of recurringToSaveArray) {
        if (item.startsWith('RRULE')) {
            const finishString = total - 1 === count ? '' : '';
            const stringToPush = `${item};UNTIL=${moment(end).utc().format('YYYYMMDD')}${finishString}`
            finalRecurringArray.push(stringToPush);
        } else {
            finalRecurringArray.push((item));
        }
        count++;
    }
    const recurring = finalRecurringArray.join('\n');
    const rrule = rrulestr(recurring);
    const events = rrule.all().map(date =>
        DateTime.fromJSDate(date)
            .toUTC()
            .setZone('local', { keepLocalTime: true })
            .toJSDate()
    );
    for (const a of events) {
        newEvents.push({
            id: moment(a).utc().format('YYYYMMDD'),
            start: a,
            recurring: recurringToSave,
            end: a,
            daysOfWeek: daysOfWeek,
            text: t('agenda_not_available', 'Not Available'),
            background: '#f4511e'
        })
    }
    return newEvents;
}

export default function buildEvents(t, events, newSelectedDate, start, end) {
   
    const eventsToShow = events.filter(e => !e.recurring);
    const recurringEvents = events.filter(e => e.recurring);
    for (const event of recurringEvents) {
        const eventsToAdd = generateEventsToShow(t, event.recurring, newSelectedDate, event.daysOfWeek, start, end);
        
        for (const e of eventsToAdd) {
            eventsToShow.push(e);
        }
    }

    const result = eventsToShow.map(e => {
        return {
            ...e,
            start: moment(e.start, "YYYYMMDD").format("YYYY-MM-DD"),
            end: moment(e.end, "YYYYMMDD").format("YYYY-MM-DD"),
        }
    });
    return result;
}