import React, { useContext } from 'react';
import './privacy.css';
import { FirebaseContext } from '../../firebaseConnect';

export default () => {
    const { t } = useContext(FirebaseContext)
    return (
        <div className="container-back">
            <div className="container-back-content">
                <h1 className="page-title">
                    <div className="keep-in-line bold">
                        {t('policy_title', 'Policy')}
                    </div>
                </h1>
                <div className="about-title bold first-title">
                    <h4 className="first_title">{t('policy_1', '...')}</h4>
                    <script id="CookieDeclaration" src="https://consent.cookiebot.com/fa4e2098-0ea5-4002-9acd-b49f9d6ba932/cd.js"
    type="text/javascript" async></script>
                </div>
            </div>
        </div>
    )
}
