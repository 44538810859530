import firebaseConfig from './config'
import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import * as Fire from 'firebase';

class Firebase {
    constructor() {
        app.initializeApp(firebaseConfig);
        this.auth = app.auth()
        this.db = app.firestore();
        this.storage = app.storage().ref();
        this.functions = app.functions();
        this.FieldValue = Fire.firestore.FieldValue
        this.uiConfig = {
            signInFlow: 'popup',
            signInSuccessUrl: '/VendorEdit',
            signInOptions: [
                {
                    provider: Fire.auth.GoogleAuthProvider.PROVIDER_ID,
                    scopes: [
                        'https://www.googleapis.com/auth/calendar',
                        'https://www.googleapis.com/auth/calendar.events',
                        'https://www.googleapis.com/auth/calendar.addons.execute'
                    ]
                },
                Fire.auth.FacebookAuthProvider.PROVIDER_ID,
                Fire.auth.EmailAuthProvider.PROVIDER_ID
            ]
        };
        var provider = new Fire.auth.GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/calendar');
        this.provider = provider
    }

    uploadImages = async (vendorId, pictures) => {
        const filesPath = `vendor-images/${vendorId}`;
        const picturesUrls = [];
        for (const picture of pictures) {
            const filePath = `${filesPath}/${picture.name}`;
            const task = await this.storage.child(filePath).put(picture);
            const fileUrl = await task.ref.getDownloadURL()
            const finalUrl = { src: fileUrl, width: 3, height: 3 }
            picturesUrls.push(finalUrl)
        }
        return picturesUrls;
    }
}

const firebase = new Firebase();
export default firebase;