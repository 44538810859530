import React, { useState } from 'react';
import AppRouter from '../AppRouter/AppRouter';
import firebase, { FirebaseContext } from '../../firebaseConnect';
import useAuth from '../../hooks/useAuth'
import { Router } from "react-router-dom";
import '../../i18n';
import { useTranslation } from 'react-i18next';
import createHistory from 'history/createBrowserHistory';
export const history = createHistory();

const App = () => {

    const [isLoadingLocal, setIsLoadingLocal] = useState(0);

    const addIsLoading = (location) => setIsLoadingLocal(prevState => {
        const finalState = prevState + 1;
        return finalState
    })

    const removeIsLoading = (location) => setIsLoadingLocal(prevState => {
        const finalState = prevState - 1 < 0 ? 0 : prevState - 1;
        return finalState
    })

    const resetIsLoading = (location) => {
        setIsLoadingLocal(0)
    }

    const isLoading = isLoadingLocal > 0

    const { t, i18n } = useTranslation();


    const changeLanguage = code => {
        i18n.changeLanguage(code);
    };

    const [searchContext, setSearchContext] = useState({
        vendor: null,
        date: null,
        city: null
    });

    const redirectAfterLogin = async (authUser, userProfile) => {
        if (authUser !== null && userProfile !== null) {
            await firebase.db.collection('vendors').doc(authUser.uid).set({ ...userProfile, emailConfirmed: true })
        }
    }
    const auth = useAuth(redirectAfterLogin, () => history.push(`/${i18n.language}`), addIsLoading, removeIsLoading, resetIsLoading);
    return (
        <FirebaseContext.Provider value={
            {
                user: auth.vendor,
                firebase,
                searchContext,
                setSearchContext,
                t,
                changeLanguage,
                addIsLoading,
                removeIsLoading,
                resetIsLoading,
                isLoading,
                handleLogin: auth.handleLogin,
                handleSignOut: auth.handleSignOut,
                language: i18n.language
            }
        }>
            <Router history={history}>
                <AppRouter language={i18n.language} />
            </Router>
        </FirebaseContext.Provider>
    )
}

export default App;