import React, { useContext } from 'react';
import './privacy.css';
import { FirebaseContext } from '../../firebaseConnect';

export default () => {
    const { t } = useContext(FirebaseContext)
    return (
        <div className="container-back">
            <div className="container-back-content">
                <h1 className="page-title">
                    <div className="keep-in-line bold">
                        {t('policy_title', 'Policy')}
                    </div>
                </h1>
                <div className="about-title bold first-title">
                    <h4 className="first_title">{t('policy_1', '...')}</h4>
                    <p>{t('policy_2', '...')}</p>
                    <p>{t('policy_3', '...')}</p>
                    <p>{t('policy_4', '...')}</p>
                    <p>{t('policy_5', '...')}</p>
                    <p>{t('policy_6', '...')}</p>
                    <p>{t('policy_7', '...')}</p>
                    <p>{t('policy_8', '...')}</p>
                    <p>{t('policy_9', '...')}</p>
                    <p>{t('policy_10', '...')}</p>
                    <p>{t('policy_11', '...')}</p>
                    <p>{t('policy_12', '...')}</p>
                    <p>{t('policy_13', '...')}</p>
                    <p>{t('policy_14', '...')}</p>
                    <p>{t('policy_15', '...')}</p>
                    <p>{t('policy_16', '...')}</p>
                    <p>{t('policy_17', '...')}</p>
                    <p>{t('policy_18', '...')}</p>
                    <p>{t('policy_19', '...')}</p>
                    <p>{t('policy_20', '...')}</p>
                    <p>{t('policy_21', '...')}</p>
                    <p>{t('policy_22', '...')}</p>
                    <p>{t('policy_23', '...')}</p>
                    <p>{t('policy_24', '...')}</p>
                    <p>{t('policy_25', '...')}</p>
                    <p>{t('policy_26', '...')}</p>
                    <p>{t('policy_27', '...')}</p>
                    <p>{t('policy_28', '...')}</p>
                    <p>{t('policy_29', '...')}</p>
                    <p>{t('policy_30', '...')}</p>
                    <p>{t('policy_31', '...')}</p>
                    <p>{t('policy_32', '...')}</p>
                    <p>{t('policy_33', '...')}</p>
                    <p>{t('policy_34', '...')}</p>
                    <p>{t('policy_35', '...')}</p>
                    <p>{t('policy_36', '...')}</p>
                </div>
            </div>
        </div>
    )
}
