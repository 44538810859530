export const firebaseConfig = {
    apiKey: "AIzaSyDFCJDXkHz2pSuRldgvcDaR8OPyTRa7FC8",
    authDomain: "lebonjourcanada.firebaseapp.com",
    databaseURL: "https://lebonjourcanada.firebaseio.com",
    projectId: "lebonjourcanada",
    storageBucket: "lebonjourcanada.appspot.com",
    messagingSenderId: "424483981333",
    appId: "1:424483981333:web:b263a510966dc371d0661c",
    measurementId: "G-PR698MCHXG"
  };
export default firebaseConfig;