import React, { useContext, useState, useRef, useEffect } from 'react';
import { FirebaseContext } from '../../firebaseConnect';
import "./VendorLogin.css";
// import { GoogleLogin } from 'react-google-login';
import { useHistory } from "react-router-dom";
import mobiscroll from '@mobiscroll/react';
import googlebutton from './images/btn_google_signin_light_normal_web.png';
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
const Config = require("../../firebaseConnect/apiGoogleconfig.json");

const VendorLogin = (props) => {
  const redirectTo = props.match.params.redirectTo;
  const { firebase, t, language, user } = useContext(FirebaseContext);
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validation, setValidation] = useState({
    email: null,
    password: null,
    errorMessage: null
  });

  useEffect(() => {
    const isAuthenticated = !!user;
    if (isAuthenticated) {
      const isAdmin = !!user && user.isAdmin;
      if (isAdmin) {
        if (redirectTo === 'SendInvitation') {
          history.push(`/${language}/SendInvitation`)
        }
        else{
          history.push(`/${language}/VendorDirectory`)
        }
      }
      else {
        history.push(`/${language}/VendorEdit`)
      }
    }
  }, [user, redirectTo])

  const handleForgotPassword = () => {
    if (validateAllFields(false)) {
      firebase.auth.languageCode = language;
      firebase.auth.sendPasswordResetEmail(email).then(function () {
        eventRef.current.instance.show();
      }).catch(function (error) {
        var errorMessage = t(error.message, error.message);
        setValidation(previous => {
          return {
            ...previous,
            errorMessage
          }
        })
      });
    }
  }

  const validateAllFields = (isLogin) => {
    const newValidation = {
      email: null,
      password: null
    }

    let allValid = true;

    if (!email) {
      newValidation.email = t('This field is required', 'This field is required')
      allValid = false;
    }
    if (isLogin) {
      if (!password) {
        newValidation.password = t('This field is required', 'This field is required')
        allValid = false;
      }
    }

    setValidation({
      ...newValidation
    });

    return allValid;
  }
  const emailChange = event => {
    setEmail(event.target.value)
  }

  const passwordChange = event => {
    setPassword(event.target.value)
  }

  const eventRef = useRef(null);

  const handleEmailLogin = async () => {
    if (validateAllFields(true)) {
      await firebase.auth.signInWithEmailAndPassword(email.trim(), password).catch(function (error) {

        var errorMessage = t(error.message, error.message);
        setValidation(previous => {
          return {
            ...previous,
            errorMessage
          }
        })
      });
    }
  }

  const btn = [{
    text: 'Ok',
    handler: 'set'
  }
  ];

  return (

    <div className="container-back">
      <div className="container-back-content-full">
        <h1 className="page-title">Sign In</h1>
        <mobiscroll.Form className="mbsc-form-grid" theme="savemydate">
          <mobiscroll.Popup
            ref={eventRef}
            display="center"
            buttons={btn}
          >
            <div className="mbsc-align-center mbsc-padding">
              <h4 className="md-text-center">{t('forgot_email_sent', 'Check your email and follow the instructions to reset your password')}</h4>
            </div>
          </mobiscroll.Popup>
          <div className="mbsc-grid">
            {validation.errorMessage && <span className="mbsc-err-msg">{validation.errorMessage}</span>}
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-3">
                {/* <div className="vendor-or">OR</div> */}
                <div className="mbsc-btn-group-block">
                  <mobiscroll.Input
                    name='email'
                    autoComplete="username"
                    value={email}
                    valid={!validation.email}
                    errorMessage={validation.email}
                    onChange={emailChange}
                    labelStyle="floating" placeholder={t("Enter your email address", "Enter your email address")}>{t("Email", "Email")}</mobiscroll.Input>
                </div>
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-3">
                <div className="mbsc-btn-group-block">
                  <mobiscroll.Input
                    name='password'
                    autoComplete="current-password"
                    value={password}
                    valid={!validation.password}
                    errorMessage={validation.password}
                    type="password" passwordToggle
                    onChange={passwordChange}
                    labelStyle="floating" placeholder="Enter your Password">{t("Password", "Password")}</mobiscroll.Input>
                </div>
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-3">
                <div className="mbsc-btn-group-block">
                  <mobiscroll.Button onClick={handleEmailLogin}>{t("Login With Your Email", "Login With Your Email")}</mobiscroll.Button>
                </div>
              </div>
            </div>
            <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-xl-3">
                <div className="mbsc-btn-group-block">
                  <mobiscroll.Button className='vendor-login-dont' onClick={handleForgotPassword}>{t("I forgot my password", "I forgot my password")}</mobiscroll.Button>
                </div>
              </div>
            </div>
          </div>
        </mobiscroll.Form>
      </div>
    </div >
  );
}

export default VendorLogin;