import React, { useContext } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import mobiscroll from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { FirebaseContext } from '../../firebaseConnect';

const FAQ = () => {
    const { t } = useContext(FirebaseContext)
    return (
        <div className='faq-main'>
            <h1>FAQ Joli Rendez-vous</h1>
            <p>{t('faq_top', '...')}</p>
            <Accordion>
                <Card className='faq-card'>
                    <Card.Header className='faq-card-header'>
                        <Accordion.Toggle as={mobiscroll.Button} eventKey="0">
                            {t('I AM A FUTURE BRIDE', 'I AM A FUTURE BRIDE')}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className='faq-card-body'>
                            <ol>
                                <li className='faq-li-question'>{t('faq_1', '...')}
                                    <ul className='faq-li-answer'>
                                        <li>{t('faq_2', '...')}</li>
                                    </ul>
                                </li>
                                <li className='faq-li-question'>{t('faq_3', '...')}
                                    <ul className='faq-li-answer'>
                                        <li>{t('faq_4', '...')}</li>
                                    </ul>
                                </li>
                                <li className='faq-li-question'>{t('faq_5', '...')}
                                    <ul className='faq-li-answer'>
                                        <li>{t('faq_6', '...')}</li>
                                    </ul>
                                </li>
                            </ol>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='faq-card'>
                    <Card.Header className='faq-card-header'>
                        <Accordion.Toggle as={mobiscroll.Button} eventKey="1">
                            {t('I AM A WEDDING VENDOR', 'I AM A WEDDING VENDOR')}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body className='faq-card-body'>
                            <ol>
                                <li className='faq-li-question'>{t('faq_7', '...')}
                                    <ul className='faq-li-answer'>
                                        <li>{t('faq_8', '...')}</li>
                                    </ul>
                                </li>
                                <li className='faq-li-question'>{t('faq_9', '...')}
                                    <ul className='faq-li-answer'>
                                        <li>{t('faq_10', '...')}
                                            <ul className='faq-li-answer'>
                                                <li>{t('faq_11', '...')}</li>
                                                <li>{t('faq_12', '...')}</li>
                                                <li>{t('faq_13', '...')}</li>
                                                <li>{t('faq_14', '...')}</li>
                                                <li>{t('faq_15', '...')}</li>
                                            </ul>
                                            {t('faq_16', '...')}
                                        </li>
                                    </ul>
                                </li>
                                <li className='faq-li-question'>{t('faq_17', '...')}
                                    <ul className='faq-li-answer'>
                                        <li>{t('faq_18', '...')}</li>
                                    </ul>
                                </li>
                                <li className='faq-li-question'>{t('faq_19', '...')}
                                    <ul className='faq-li-answer'>
                                        <li>{t('faq_20', '...')}
                                            <ul className='faq-li-answer'>
                                                <li>{t('faq_21', '...')}</li>
                                                <li>{t('faq_22', '...')}</li>
                                                <li>{t('faq_23', '...')}</li>
                                                <li>{t('faq_24', '...')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className='faq-li-question'>{t('faq_25', '...')}
                                    <ul className='faq-li-answer'>
                                        <li>{t('faq_26', '...')}
                                            <ul className='faq-li-answer'>
                                                <li>{t('faq_27', '...')}</li>
                                                <li>{t('faq_28', '...')}</li>
                                                <li>{t('faq_29', '...')}</li>
                                                <li>{t('faq_30', '...')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className='faq-li-question'>{t('faq_31', '...')}
                                    <ul className='faq-li-answer'>
                                        <li>{t('faq_32', '...')}</li>
                                    </ul>
                                </li>
                                <li className='faq-li-question'>{t('faq_33', '...')}
                                    <ul className='faq-li-answer'>
                                        <li>{t('faq_34', '...')}
                                            <ul className='faq-li-answer'>
                                                <li>{t('faq_35', '...')}</li>
                                                <li>{t('faq_36', '...')}</li>
                                                <li>{t('faq_37', '...')}</li>
                                                <li>{t('faq_38', '...')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className='faq-li-question'>{t('faq_39', '...')}
                                    <ul className='faq-li-answer'>
                                        <li>{t('faq_40', '...')}
                                            <ul className='faq-li-answer'>
                                                <li>{t('faq_41', '...')}</li>
                                                <li>{t('faq_42', '...')}</li>
                                                <li>{t('faq_43', '...')}</li>
                                                <li>{t('faq_44', '...')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <div className='faq-any-question'>
                                <li>{t('faq_45', '...')}</li>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}

export default FAQ;
